import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { ImCancelCircle } from "react-icons/im";
import { ToastContainer, toast } from 'react-toastify';
import { useGlobalContext } from '../../context';
import UserSideNav from '../components/UserSideNav';
import spinner from '../../images/Spinnergrey.svg'
import {GiHamburgerMenu} from "react-icons/gi";
import {AiFillHome} from "react-icons/ai";
import {BsWalletFill} from "react-icons/bs"; 
import {BiTransfer} from "react-icons/bi";  
import {BiLogOut} from "react-icons/bi";
import {FaUsers} from "react-icons/fa";
import {BiHistory} from "react-icons/bi";
import {MdCandlestickChart} from "react-icons/md";
import {BiSolidWallet} from "react-icons/bi";  



const UserKyc = () => {
  //universal variables -------------------
  const{ allUserMongoData} = useGlobalContext();
  const userToken = sessionStorage.getItem('userInfo');


  //user side nav----------------
  const [navActive, updateNavActive] = useState(null)

  useEffect(()=>{
    if(navActive){
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
    }
  }, [navActive])
  
  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const userDashLinks = [
      { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/deposit', name: 'Deposit', icon: <BsWalletFill className='cursor-pointer text-[18px]  text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/user/trade', name: 'Trade', icon: <MdCandlestickChart className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transfer', name: 'Transfer', icon: <BiTransfer className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/kyc', name: 'KYC', icon: <FaUsers className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transactions', name: 'Transactions', icon: <BiHistory className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
  ];

  //dropzone for image upload-------
  const [Idimages, setIdImages] = useState([]);
  const [selectedIdImages, setSelectedIdImages] = useState([]);

  const [Passimages, setPassImages] = useState([]);
  const [selectedPassImages, setSelectedPassImages] = useState([]);

  const handleIdDrop = (acceptedIdFiles) => {
    setIdImages((prevImages) => [...prevImages, ...acceptedIdFiles]);
    setSelectedIdImages((prevImages) => [...prevImages, ...acceptedIdFiles]);
  };

  const handlePassDrop = (acceptedPassFiles) => {
    setPassImages((prevImages) => [...prevImages, ...acceptedPassFiles]);
    setSelectedPassImages((prevImages) => [...prevImages, ...acceptedPassFiles]);
  };

  //submit kyc---------------------------
  const [isLoadingBtn, updateisLoadingBtn] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const submitKYC = async () =>{
    updateisLoadingBtn(true)
    setIsButtonDisabled(true)

    try {
        const formData = new FormData();
        
        // Append selected ID images to the form data
        selectedIdImages.forEach((image, index) => {
            formData.append(`idImages${index}`, image);
        });
    
        // Append selected passport images to the form data
        selectedPassImages.forEach((image, index) => {
            formData.append(`passPortImages${index}`, image);
        });
    
        // Make a POST request to your backend API
        const response = await axios.post('/api/user/kyc', formData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data', // Important header for file upload
          },
        });

        const KycData = response.data;   
    
        if(KycData.errMsg){
            toast.error(KycData.errMsg)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
            setSelectedPassImages([])
            setSelectedIdImages([])
        }else{
            toast.success(KycData.successMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
            });
            updateisLoadingBtn(false)
            setSelectedPassImages([])
            setSelectedIdImages([])
            setIsButtonDisabled(false)
        }
      } catch (error) {
        console.error('Error submitting KYC:', error);
        updateisLoadingBtn(false)
        setIsButtonDisabled(false)
        setSelectedPassImages([])
        setSelectedIdImages([])
        toast.error('An error occurred while submitting KYC, contact support.');
      }
  }

  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>

        {/* pop up message */}
        <div className='absolute w-full min-h-[100%] top-0 left-0 hidden justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[160px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-black p-4 border border-black rounded-md sm:w-[400px] ssm:w-[80%]'>
                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative "  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <h3 className='relative w-full text-center text-white text-[16px] font-[550] pt-[1rem]'>Choose Payment Method</h3>
            </div>
        </div>
        
        <UserSideNav hideNav={hideNav} logout = '/signin' logoutBtn = {clearSess} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} logouticon = {<BiLogOut className='cursor-pointer text-[20px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>} />
        
        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <nav className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-[#A9ABAD]' />
                    </button>
                    
                    {/* <!-- manage notofication and user icon --> */}
                    <div className='relative flex space-x-2'>

                    {/* <!-- user balance--> */}
                    <div className='relative flex space-x-2'>
                        <div  className='relative flex justify-center items-center space-x-1 w-[120px] min-h-[38px] py-2 px-4 border border-[#2e1362] rounded-[5px] transition-all ease-in duration-200 bg-inherit hover:bg-[#5f36a2] hover:border-[#5f36a2]'>
                            <BiSolidWallet className='text-[15px] text-[#A9ABAD]'/>
                            <p className='text-[12px] text-[#A9ABAD] text-ellipsis'>
                                ${allUserMongoData && allUserMongoData.balance ? allUserMongoData.balance.toLocaleString() : '0' }
                            </p>
                        </div>
                    </div>

                    </div>
                </div>
            </nav> 

            <h3 className='text-[19px] text-center text-[#A9ABAD] pt-[6rem]'>Account Verification</h3>

            {/* all toast messages */}
            <div className='relative w-full flex justify-center items-center '>
                    <ToastContainer          
                      className="relative w-full"
                    />
            </div>
            
            {/* upload forms */}
            <div className='relative text-[#A9ABAD] w-full flex flex-col justify-center items-center space-y-8 pt-[1.5rem]  md:flex-row md:space-y-0 md:space-x-4'>
            
              <div className='w-full h-full min-h-[150px] flex flex-col items-start justify-start space-y-1  bg-[#181C26] px-4 py-6 border border-black rounded-xl sm:w-[450px]'>
                 <h5 className='text-[15px] text-white pb-[1rem]'>KYC Verification - Upload documents to verify account.</h5> 
                 <p className='text-[13px] pb-[0.50rem]'>Valid identity card (e.g Drivers license, international passport or any government approved document). </p>  

                <Dropzone onDrop={handleIdDrop} accept="image/jpeg, image/png, image/jpg, image/webp" multiple>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="relative max-w-[290px] min-h-[70px] p-2 border bg-[#474a4d] border-[#A9ABAD] rounded-md">
                            <input {...getInputProps()} />
                            <p className='text-[14px]'>Drag and drop ID images here or click to select files.</p>
                        </div>
                    )}
                </Dropzone>

                {selectedIdImages.length > 0 && (
                    <div className='relative flex justify-center items-center space-x-3 pt-[1rem]'>
                        <h4 className='text-[14px]'>Selected Images:</h4>
                        {selectedIdImages.map((image, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(image)}
                              alt='ID'
                              className="relative w-[50px] h-[50px] border border-inherit rounded-sm"
                            />
                        ))}
                    </div>
                )} 

                <p className='text-[13px] pt-[3rem] pb-[0.50rem]'>Upload two passport photographs </p>  

                <Dropzone onDrop={handlePassDrop} accept="image/jpeg, image/png, image/jpg, image/webp" multiple>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="relative max-w-[290px] min-h-[70px] p-2 border bg-[#474a4d] border-[#A9ABAD] rounded-md">
                            <input {...getInputProps()} />
                            <p className='text-[14px]'>Drag and drop passport images here or click to select files.</p>
                        </div>
                    )}
                </Dropzone>

                {selectedPassImages.length > 0 && (
                    <div className='relative flex justify-center items-center space-x-3 pt-[1rem]'>
                        <h4 className='text-[14px]'>Selected Images:</h4>
                        {selectedPassImages.map((image, index) => (
                            <img
                            key={index}
                            src={URL.createObjectURL(image)}
                            alt='ID'
                            className="relative w-[50px] h-[50px] border border-inherit rounded-sm"
                            />
                        ))}
                    </div>
                )} 

                <div className='w-full flex justify-start items-center pb-[0.50rem] pt-[2rem]'>
                    <button disabled = {isButtonDisabled} onClick={()=> submitKYC()} className='w-[100px] min-h-[40px] flex justify-center items-center space-x-2 text-black tracking-wide text-[14px] font-[650] px-2 py-2 bg-[#c8c8c8] border border-[#c8c8c8] rounded-md'>
                        <p className='text-[14px]'>Submit</p>
                        {isLoadingBtn && <img src={spinner} className='w-[20px] h-[20px]' alt='loading' />} 
                    </button>
                </div>

              </div>

            </div>


        </div>

    </div>
  )
}

export default UserKyc