import React from 'react';
import { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context';
import UserSideNav from '../components/UserSideNav';
import {GiHamburgerMenu} from "react-icons/gi";
import {AiFillHome} from "react-icons/ai";
import {BsWalletFill} from "react-icons/bs"; 
import {BiTransfer} from "react-icons/bi";  
import {BiLogOut} from "react-icons/bi";
import {FaUsers} from "react-icons/fa";
import {BiHistory} from "react-icons/bi";
import {MdCandlestickChart} from "react-icons/md";
import {BiSolidWallet} from "react-icons/bi";  
import ReactDataTable from '../components/ReactDataTable';


const UserTransactions = () => {

  //universal variables  
  const{allUserMongoData, allUserTrade, allUserDep, allUserWithdraw} = useGlobalContext();

  //user side nav------------------------------
  const [navActive, updateNavActive] = useState(null)

  useEffect(()=>{
    if(navActive){
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
    }
  }, [navActive])
  
  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const userDashLinks = [
      { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/deposit', name: 'Deposit', icon: <BsWalletFill className='cursor-pointer text-[18px]  text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/user/trade', name: 'Trade', icon: <MdCandlestickChart className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transfer', name: 'Transfer', icon: <BiTransfer className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/kyc', name: 'KYC', icon: <FaUsers className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transactions', name: 'Transactions', icon: <BiHistory className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
  ];

  //table----------------------------------------
  const [sortedData, setSortedData] = useState([]); 

  useEffect(()=>{

    const mappedTradeData = allUserTrade.map(trade => ({
          date: trade.tradedate,
          type: 'XTrading',
          amount: trade.tradeamount,
          receiver: 'XTrading',
          status: trade.tradestatus,
    }));

    const mappedDepData = allUserDep.map(dep => ({
        date: dep.date,
        type: dep.deptype,
        amount: dep.depamnt,
        receiver: 'Self Deposit',
        status: 'Completed',
    }));

    const mappedWithdrawData = allUserWithdraw.map(withdraw => ({
        date: withdraw.withdrawdate,
        type: withdraw.withdrawtype + ' Transfer',
        amount: withdraw.withdrawamount,
        receiver: withdraw.receiverdetails,
        status: withdraw.withdrawstatus,
    }));

    const combinedData = [...mappedTradeData, ...mappedDepData, ...mappedWithdrawData];
    const sortedData = combinedData.sort((a, b) => new Date(b.date) - new Date(a.date));

    setSortedData(sortedData)

  }, [allUserTrade, allUserDep, allUserWithdraw])

  const columns = [
    {
        name: 'Date',
        selector: (row) => row.date,
        cell: (row) => (
          <div style={{  whiteSpace: 'nowrap' }}>
            {new Date(row.date).toLocaleString()}
          </div>
        )
    },
    {
        name: 'Transaction Type', 
        selector: row => row.type,
        cell: (row) =>(
            <div style={{ whiteSpace: 'nowrap'}}>
                {row.type}
            </div>
        )
    },
    {
        name: 'Amount', 
        selector: row => row.amount,
        cell: (row) => {
          const amountNumber = parseFloat(row.amount); // Convert to number
      
          return (
            <div style={{  whiteSpace: 'nowrap', letterSpacing: '0.60px' }}>
              ${amountNumber.toLocaleString()}
            </div>
          );
        }
    },   
    {
          name: 'Receiver', 
          selector: row => row.receiver,
          cell: (row) =>(
              <div style={{whiteSpace:'normal'}}>
                  {row.receiver}
              </div>
          )
    },
    {
        name: 'Status', 
        selector: row => row.status,
        cell: (row) => (
          <div
            style={{
              whiteSpace: 'nowrap', // Prevent line breaks
              backgroundColor: 
              row.status === 'Pending' ? '#FFBF00' : 
              row.status === 'Declined' ? '#cc0000' :
              '#32CD32', // Lime Green for Completed, Amber for others
              padding: '1.0rem',
              color: 'white',
              border: '10px inherit solid',
              borderRadius: '3px',
              height: '30px',
              minWidth: '104.8px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              fontSize: '12px',
            }}
          >
            {row.status}
          </div>
        )
    }      
      
  ];

  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>
        
        <UserSideNav hideNav={hideNav} logout = '/signin' logoutBtn = {clearSess} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} logouticon = {<BiLogOut className='cursor-pointer text-[20px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>} />
        
        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <nav className="relative w-full min-h-[100px] flex justify-center items-start">    
                  <div className="relative w-full min-h-[50px] flex justify-between items-center">
                        {/* <!-- hamburger menu --> */}
                        <button onClick={() => updateNavActive(true) } className="relative">
                            <GiHamburgerMenu className='text-[20px] text-[#A9ABAD]' />
                        </button>
                
                        {/* <!-- user balance--> */}
                        <div className='relative flex space-x-2'>
                            <div  className='relative flex justify-center items-center space-x-1 w-[120px] min-h-[38px] py-2 px-4 border border-[#2e1362] rounded-[5px] transition-all ease-in duration-200 bg-inherit hover:bg-[#5f36a2] hover:border-[#5f36a2]'>
                                <BiSolidWallet className='text-[15px] text-[#A9ABAD]'/>
                                <p className='text-[12px] text-[#A9ABAD] text-ellipsis'>
                                    ${allUserMongoData && allUserMongoData.balance ? allUserMongoData.balance.toLocaleString() : '0' }
                                </p>
                            </div>
                        </div>
                  </div>
            </nav> 


            {/* table */}
            <h3 className='w-full text-center text-[#A9ABAD] text-[19px] pb-[7rem]'>Transaction History</h3>

            <div className='relative w-full flex justify-center items-center mx-auto lg:w-[800px]'>
                <div className='relative w-full flex justify-center items-center'>
                    <ReactDataTable columns = {columns}  data = {sortedData} />   
                </div>
            </div>
        </div>

    </div>
  )
}

export default UserTransactions