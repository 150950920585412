import React from 'react';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ImCancelCircle } from "react-icons/im";
import { useGlobalContext } from '../../context';
import UserSideNav from '../components/UserSideNav';
import axios from 'axios';
import {GiHamburgerMenu} from "react-icons/gi";
import {AiFillHome} from "react-icons/ai";
import {BsWalletFill} from "react-icons/bs"; 
import {BiTransfer} from "react-icons/bi";  
import {BiLogOut} from "react-icons/bi";
import {FaUsers} from "react-icons/fa";
import {BiHistory} from "react-icons/bi";
import {MdCandlestickChart} from "react-icons/md";
import {BiSolidWallet} from "react-icons/bi";  


const UserProfile = () => {
  //universal variables  
  const{ allUserMongoData} = useGlobalContext();




  //get user header-------------------------
  const userToken = sessionStorage.getItem('userInfo');

    const userConfig = {
        headers: {
        Authorization: `Bearer ${userToken}`,
    },

  };


  //user side nav----------------
  const [navActive, updateNavActive] = useState(null)

  useEffect(()=>{
    if(navActive){
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
    }
  }, [navActive])
  
  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const userDashLinks = [
      { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/deposit', name: 'Deposit', icon: <BsWalletFill className='cursor-pointer text-[18px]  text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/user/trade', name: 'Trade', icon: <MdCandlestickChart className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transfer', name: 'Transfer', icon: <BiTransfer className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/kyc', name: 'KYC', icon: <FaUsers className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transactions', name: 'Transactions', icon: <BiHistory className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
  ];

  //profile form-------------------
  const [formActive, updateFormState] = useState(null)
  const [formValues, upFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  })

  const submitProfileForm = async (e) =>{
    e.preventDefault();

    try {
        const profilePost =  await axios.post('/api/user/profile', {...formValues}, userConfig)
        const profileData = profilePost.data;   

        //checks if data contains any errors
        if(profileData.errMsg){
            toast.error(profileData.errMsg)
        }else{
            toast.success(profileData.successMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
            });
            upFormValues({
                name: '',
                email: '',
                phone: '',
                address: ''
            })
            setTimeout(()=>{
                window.location.reload()
            }, 4000)
            
        }
    } catch (error) {
        console.log(error)
    }
  }


  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>

        {/* pop up message */}
        {formActive && <div className='absolute w-full min-h-[100%] top-0 left-0 pt-[5rem] flex justify-center items-start bg-[#111111c9] z-50'>
            <div className='relative min-h-[460px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-black p-4 border border-black rounded-md sm:w-[350px] ssm:w-[80%]'>
                <div className='relative w-full flex justify-end items-center'>
                    <button onClick={()=> updateFormState(false)} className="relative "  >
                        <ImCancelCircle className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1]' />
                    </button>
                </div>

            
                <h4 className="text-left text-[15px] w-full font-[550] text-[#A9ABAD] pb-[0.50rem]">Update Profile</h4>
                
                <ToastContainer className="relative w-full" />

                <form className='w-full' onSubmit={(e)=> submitProfileForm(e)}>
                    {/* <!--  name --> */}
                    <div className="flex flex-col justify-start space-y-2 w-full pt-8">
                        <label className="font-[550] text-[13px] text-[#A9ABAD] leading-[19px] md:text-[15px]">Name</label>
                        <input type="text" name='name' value ={formValues.name} className="w-[100%] h-[40px] bg-inherit px-[5px] border border-[#363a3a] rounded-md focus:outline-none focus:border-[#7e7f87]" onChange={(e)=> {upFormValues({...formValues, [e.target.name]: e.target.value})}} />
                    </div>

                    {/* <!-- email --> */}
                    <div className="flex flex-col justify-start space-y-2 w-full pt-4">
                        <label className="font-[550] text-[13px] text-dashtextcolor leading-[19px] text-[#A9ABAD] md:text-[15px]">Email Address</label>
                        <input type="text" name='email' value ={formValues.email} className="w-[100%] h-[40px] bg-inherit px-[5px] border border-[#363a3a] rounded-md focus:outline-none focus:border-[#7e7f87]" onChange={(e)=> {upFormValues({...formValues, [e.target.name]: e.target.value})}} />
                    </div>

                    {/* <!-- phone --> */}
                    <div className="flex flex-col justify-start space-y-2 w-full pt-4">
                        <label className="font-[550] text-[13px] text-dashtextcolor leading-[19px] text-[#A9ABAD] md:text-[15px]">Phone Number</label>
                        <input type="tel" name='phone' value ={formValues.phone} className="w-[100%] h-[40px] bg-inherit px-[5px] border border-[#363a3a] rounded-md focus:outline-none focus:border-[#7e7f87]"  onChange={(e)=> {upFormValues({...formValues, [e.target.name]: e.target.value})}}/>
                    </div>

                    {/* <!-- Address --> */}
                    <div className="flex flex-col justify-start space-y-2 w-full pt-4">
                        <label className="font-[550] text-[13px] text-dashtextcolor leading-[19px] text-[#A9ABAD] md:text-[15px]">Address</label>
                        <input type="text" name='address' value ={formValues.address} className="w-[100%] h-[40px] bg-inherit px-[5px] border border-[#363a3a] rounded-md focus:outline-none focus:border-[#7e7f87]"  onChange={(e)=> {upFormValues({...formValues, [e.target.name]: e.target.value})}}/>
                    </div>

                    {/* <!-- update and cancel btns --> */}
                    <div className="w-full pb-6 pt-8 min-h-[70px] flex flex-col justify-center items-start space-y-2 ssm:flex-row ssm:justify-start ssm:items-center ssm:space-x-3 ssm:space-y-0">
                        <button className="w-[140px] h-[40px] text-[14px] bg-userDashPurple text-white border border-black rounded-md" type="submit">Update</button>
                    </div>   
                </form>            

            </div>
        </div>}

        <UserSideNav hideNav={hideNav} logout = '/signin' logoutBtn = {clearSess} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} logouticon = {<BiLogOut className='cursor-pointer text-[20px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>} />

        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <nav className="relative w-full min-h-[100px] flex justify-center items-start">    
                  <div className="relative w-full min-h-[50px] flex justify-between items-center">
                      {/* <!-- hamburger menu --> */}
                      <button onClick={() => updateNavActive(true) } className="relative">
                          <GiHamburgerMenu className='text-[20px] text-[#A9ABAD]' />
                      </button>
                    

                      {/* <!-- user balance--> */}
                      <div className='relative flex space-x-2'>
                          <div  className='relative flex justify-center items-center space-x-1 w-[120px] min-h-[38px] py-2 px-4 border border-[#2e1362] rounded-[5px] transition-all ease-in duration-200 bg-inherit hover:bg-[#5f36a2] hover:border-[#5f36a2]'>
                              <BiSolidWallet className='text-[15px] text-[#A9ABAD]'/>
                              <p className='text-[12px] text-[#A9ABAD] text-ellipsis'>
                                  ${allUserMongoData && allUserMongoData.balance ? allUserMongoData.balance.toLocaleString() : '0' }
                              </p>
                          </div>
                      </div>

                  </div>
            </nav>  

            <div className="relative min-h-[400px] w-full flex flex-col justify-start items-center space-y-6 py-5 mt-10">


                <div className="relative w-[100%] min-h-[30px] flex flex-col justify-start items-start space-y-1 sm:w-[500px]">   
                    <h5 className="text-[20px] text-left font-[500] text-[#cacbcb] ">Update Profile</h5>
                
                    <div className="flex justify-start items-center space-x-1">
                        <p className="text left w-full text-[15px] font-[450] text-[#A9ABAD] tracking-[0.30px] md:text-[15.50px]">Manage your Profile.</p>
                    </div>
                </div>
            
                <div className="relative w-[100%] min-h-[30px] flex flex-col justify-start items-start space-y-1 pt-5 sm:w-[500px]">
                    <h6 className="text-left w-full text-[15px] font-[550] text-[#A9ABAD] tracking-[0.30px] md:text-[15.50px]">Personal Information</h6>
                    <p className="text left w-full text-[14px] font-[450] text-[#A9ABAD] tracking-[0.30px] md:text-[15.50px]">Basic information e.g your name, phone number and address.</p>
                </div>
            


                <div className="relative w-[100%] min-h-[300px] bg-[#181C26] border border-[#181C26] rounded-md flex flex-col justify-start items-start sm:w-[500px] ">
                

                    <div className="w-full min-h-[50px] flex justify-between items-center border-b border-b-[#2e3032] py-5" >
                        <div className="w-1/2 px-5 flex flex-col justify-center items-start space-y-2">
                            <p className="text-[13px] text-left font-[400] text-[#cacbcb] ">Name</p>
                            <p className="text-[14px] text-left font-[500] text-white ">
                                {allUserMongoData && allUserMongoData.username ? allUserMongoData.username: '...' }
                            </p>
                        </div>
                    </div>


                    <div className="w-full min-h-[50px] flex justify-between items-center border-b border-b-[#2e3032] py-5" >
                        <div className="w-1/2 px-5 flex flex-col justify-center items-start space-y-2">
                            <p className="text-[13px] text-left font-[400] text-[#cacbcb]">Email</p>
                            <p className="text-[14px] text-left font-[500] text-white ">
                                {allUserMongoData && allUserMongoData.email ? allUserMongoData.email: '...' }
                            </p>
                        </div>
                    </div>

       
                    <div className="w-full min-h-[50px] flex justify-between items-center border-b border-b-[#2e3032] py-5" >
                        <div className="w-1/2 px-5 flex flex-col justify-center items-start space-y-2">
                            <p className="text-[13px] text-left font-[400] text-[#cacbcb]">Phone Number</p>
                            <p className="text-[14px] text-left font-[500] text-white ">
                                {allUserMongoData && allUserMongoData.phone ? allUserMongoData.phone: '...' }
                            </p>
                        </div>
                    </div>

               
                    <div className="w-full min-h-[50px] flex justify-between items-center py-5" >
                        <div className="w-1/2 px-5 flex flex-col justify-center items-start space-y-2">
                            <p className="text-[13px] text-left font-[400] text-[#cacbcb]">Address</p>
                            <p className="text-[14px] text-left font-[500] text-white ">
                                {allUserMongoData && allUserMongoData.address ? allUserMongoData.address: 'Add address' }
                            </p>
                        </div>
                    </div>

                </div>

                <div className='w-full flex justify-center items-center pt-[1rem]'>
                    <button onClick={()=> {
                            updateFormState(true); window.scrollTo(0,0);
                            upFormValues({
                                name: `${allUserMongoData.username}`,
                                email: `${allUserMongoData.email}`,
                                phone: `${allUserMongoData.phone}`,
                                address: `${allUserMongoData.address ? allUserMongoData.address : ''}`
                            });
                        }} 
                        className='w-full min-h-[45px] text-[14px] px-2 py-2 bg-userDashPurple border border-userDashPurple rounded-md sm:w-[500px]'>
                        Update Profile
                    </button>
                </div>

            </div> 
        
        
        </div>       
    </div>
  )
}

export default UserProfile