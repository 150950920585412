import { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context';
import UserSideNav from '../components/UserSideNav';
import { Link } from 'react-router-dom';
import {GiHamburgerMenu} from "react-icons/gi";
import {BiSolidUser} from "react-icons/bi";
import {AiFillHome} from "react-icons/ai" ;
import {BsWalletFill} from "react-icons/bs" ; 
import {BiTransfer} from "react-icons/bi" ; 
import {BiLogOut} from "react-icons/bi"; 
import {FaUsers} from "react-icons/fa";
import {BiHistory} from "react-icons/bi" ;
import {MdCandlestickChart} from "react-icons/md";
import {BiSolidWallet} from "react-icons/bi";
import {GiProfit} from "react-icons/gi";
import {HiCash} from "react-icons/hi";
import {FiBarChart2} from "react-icons/fi";
import React, { useMemo } from 'react';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";



const UserDash = () => {

    const{ allUserMongoData} = useGlobalContext();
    const storedWidget = sessionStorage.getItem('tradingViewWidget');

    //widget---------------------------------
    const tradingViewWidget = useMemo(() => {
        if (storedWidget) {
            return JSON.parse(storedWidget);
        } else {
            return (
                <div className='w-full min-h-[400px] pt-[3rem]'>
                    <AdvancedRealTimeChart theme="dark" width="100%" height='500px'/>
                </div>
            );
        }
    }, []);
    

    //side nav----------------------------------
    const [navActive, updateNavActive] = useState(null)

    useEffect(()=>{
        if(navActive){
          document.body.style.overflow = 'hidden'
        }else{
          document.body.style.overflow = 'auto'
        }
    }, [navActive])

    const clearSess = () =>{
        window.sessionStorage.clear();
    }

    const hideNav = () =>{
        updateNavActive(false)
    }

    const userDashLinks = [
        { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/deposit', name: 'Deposit', icon: <BsWalletFill className='cursor-pointer text-[18px]  text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/user/trade', name: 'Trade', icon: <MdCandlestickChart className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/transfer', name: 'Transfer', icon: <BiTransfer className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/kyc', name: 'KYC', icon: <FaUsers className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/transactions', name: 'Transactions', icon: <BiHistory className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    ];

  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>
        
        <UserSideNav hideNav={hideNav} logout = '/signin' logoutBtn = {clearSess} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} logouticon = {<BiLogOut className='cursor-pointer text-[20px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>} />
        
        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <nav className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-[#A9ABAD]' />
                    </button>
                    
                    {/* <!-- manage notofication and user icon --> */}
                    <div className='relative flex space-x-2'>

                        {allUserMongoData.recoveryphrase ?
                            <Link to = '/user/wallets' className='relative flex justify-center items-center space-x-1 w-[120px] h-[30px] py-2 px-4 border border-[#2e1362] rounded-[20px] transition-all ease-in duration-200 bg-inherit hover:bg-[#5f36a2] hover:border-[#5f36a2]'>
                                <BiSolidWallet className='text-[15px] text-[#A9ABAD]'/>
                                <p className='text-[12px] text-[#A9ABAD] text-ellipsis'>Linked</p>
                            </Link>
                            :
                            <Link to = '/user/wallets' className='relative text-white text-[14px] min-w-[90px] min-h-[40px] flex justify-center items-center space-x-1 p-2 border border-black rounded-md transition-all ease-in duration-200 bg-userDashPurple hover:bg-[#4239bc]'>
                                <BiSolidWallet className='text-[15px]'/>
                                <p className='text-[13px]'>connect wallet</p>                         
                            </Link>
                        }

                        <Link to="/user/updateprofile" className="relative flex justify-end items-center">
                            <BiSolidUser className='relative cursor-pointer text-[#A9ABAD] text-[22px]' />
                        </Link>

                    </div>

                </div>
            </nav> 

            {/* dashboard information display */}
            <div className='relative w-full min-h-[5rem] flex items-center justify-center'>
                <div className='w-full flex flex-col justify-center items-center space-y-4 sm:justify-items-center st:space-y-0 st:grid st:grid-cols-2 st:gap-[0.60rem] mi:gap-[1rem] mi:grid-cols-4 '>
                   
                   <div className='relative w-full min-h-[150px] flex flex-col justify-start items-start space-y-6 border border-[#1A1A1C] rounded-md bg-[#181C26] p-2 '>
                        <div className='relative w-full flex justify-between items-center'>
                            <div className='relative flex justify-start items-center space-x-1'>
                                <BiSolidWallet className='text-[14px] shrink-0'/>
                                <h2 className='text-[#868e98] text-[15px]'>Balance</h2>
                            </div>

                            <p className='text-[#868e98] text-[13px]'>USD</p>
                        </div>
                        
                        <p className='text-[#ececec] text-[19px] tracking-wider'>
                            ${allUserMongoData && allUserMongoData.balance ? allUserMongoData.balance.toLocaleString() : '0' }
                        </p>


                        <div className='absolute bottom-[6px] right-[8px] w-full flex justify-end items-end'>
                            <p className='relative text-[#089981] text-[11px]'>+0.00%</p> 
                        </div>    
                   </div>

                   <div className='relative w-full min-h-[150px] flex flex-col justify-start items-start space-y-6 border border-[#1A1A1C] rounded-md bg-[#181C26] p-2 '>
                        <div className='relative w-full flex justify-between items-center'>
                            <div className='relative flex justify-start items-center space-x-1'>
                                <GiProfit className='text-[14px] shrink-0'/>
                                <h2 className='text-[#868e98] text-[15px]'>Profit</h2>
                            </div>

                            <p className='text-[#868e98] text-[13px]'>USD</p>
                        </div>
                        
                        <p className='text-[#ececec] text-[19px] tracking-wider'>
                            ${allUserMongoData && allUserMongoData.profit ? allUserMongoData.profit.toLocaleString() : '0' }
                        </p>    
                   </div>

                   <div className='relative w-full min-h-[150px] flex flex-col justify-start items-start space-y-6 border border-[#1A1A1C] rounded-md bg-[#181C26] p-2 '>
                        <div className='relative w-full flex justify-between items-center'>
                            <div className='relative flex justify-start items-center space-x-1'>
                                <HiCash className='text-[15px] shrink-0'/>
                                <h2 className='text-[#868e98] text-[15px]'>Bonus</h2>
                            </div>

                            <p className='text-[#868e98] text-[13px]'>USD</p>
                        </div>
                        
                        <p className='text-[#ececec] text-[19px] tracking-wider'>
                            ${allUserMongoData && allUserMongoData.bonus ? allUserMongoData.bonus.toLocaleString() : '0'}
                        </p>    
                   </div>

                   <div className='relative w-full min-h-[150px] flex flex-col justify-start items-start space-y-6 border border-[#1A1A1C] rounded-md bg-[#181C26] p-2 '>
                        <div className='relative w-full flex justify-between items-center'>
                            <div className='relative flex justify-start items-center space-x-1'>
                                <FiBarChart2 className='text-[14px] shrink-0'/>
                                <h2 className='text-[#868e98] text-[15px]'>Active Trades</h2>
                            </div>
                        </div>
                        
                        <p className='text-[#ececec] text-[19px]'>
                            {allUserMongoData && allUserMongoData.activetrades ? allUserMongoData.activetrades : '0'}
                        </p>     
                   </div>

                </div>
            </div>
            
            {tradingViewWidget}
            
        </div>   
    </div>
  )
}

export default UserDash