import React from 'react';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ImCancelCircle } from "react-icons/im";
import { useGlobalContext } from '../../context';
import UserSideNav from '../components/UserSideNav';
import axios from 'axios';
import {GiHamburgerMenu} from "react-icons/gi";
import {AiFillHome} from "react-icons/ai";
import {BsWalletFill} from "react-icons/bs"; 
import {BiTransfer} from "react-icons/bi";  
import {BiLogOut} from "react-icons/bi";
import {FaUsers} from "react-icons/fa";
import {BiHistory} from "react-icons/bi";
import {MdCandlestickChart} from "react-icons/md";
import {BiSolidWallet} from "react-icons/bi";  
import {BsFillArrowRightCircleFill} from "react-icons/bs";
import {BsChevronDown} from "react-icons/bs"
import spinner from '../../images/SpinnerTrans.svg'
import { useNavigate } from 'react-router-dom';

const Usertrade = () => {
  const navigate = useNavigate();
  //universal variables -------------------
  const{fetchUserData, allUserMongoData} = useGlobalContext();

  // useEffect(()=>{
  //   fetchUserData();
  // }, []);

  //get user header-------------------------
  const userToken = sessionStorage.getItem('userInfo');

  const userConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  //user side nav----------------
  const [navActive, updateNavActive] = useState(null)

  useEffect(()=>{
    if(navActive){
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
    }
  }, [navActive])
  
  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const userDashLinks = [
      { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/deposit', name: 'Deposit', icon: <BsWalletFill className='cursor-pointer text-[18px]  text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/user/trade', name: 'Trade', icon: <MdCandlestickChart className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transfer', name: 'Transfer', icon: <BiTransfer className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/kyc', name: 'KYC', icon: <FaUsers className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transactions', name: 'Transactions', icon: <BiHistory className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
  ];

  //profit calculator-------------
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select Plan'); 
  const [expReturn, updateExpReturn] = useState(null)
  const [planAmount, updatePlanAmount] = useState({
    amount: "",
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const doProfitCalc = () =>{

    const Amount = planAmount.amount;

    if(selectedOption === 'Select Plan'){
      toast.error('Please select plan', {
        position:"top-center",
        autoClose: 3000,
        hideProgressBar:false,
        newestOnTop:true,
        closeOnClick:true,
        rtl:false,
        pauseOnFocusLoss:false,
        draggable:false,
        pauseOnHover: false,   
      });
      return updatePlanAmount({amount: ""})
    }

    if(!Amount){
      toast.error('Please input amount', {
        position:"top-center",
        autoClose: 3000,
        hideProgressBar:false,
        newestOnTop:true,
        closeOnClick:true,
        rtl:false,
        pauseOnFocusLoss:false,
        draggable:false,
        pauseOnHover: false,   
      });
      updateExpReturn()
      return updatePlanAmount({amount: ""})
    }

    if (selectedOption === 'Standard Plan') {
      if (Amount < 1000 || Amount > 4999) {
        toast.error('Please input a valid figure between 1000 and 4999', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        updatePlanAmount({amount: ""})  
        return updateExpReturn()
      }else{
        const StCalc = (Amount *  (0.70 / 100) * 60).toFixed(2);
        const UpdatedStReturn = (Number(Amount) + Number(StCalc)).toFixed(2);
        return updateExpReturn(Number(UpdatedStReturn).toLocaleString(`us-EN`))   
      }
    }

    if (selectedOption === 'Silver Plan') {
      if (Amount < 5000 || Amount > 14999) {
        toast.error('Please input a valid figure between 5000 and 14999', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        updateExpReturn();
        return updatePlanAmount({amount: ""})
      }else{
        const StCalc = (Amount *  (1.10 / 100) * 60).toFixed(2);
        const UpdatedStReturn = (Number(Amount) + Number(StCalc)).toFixed(2);
        return updateExpReturn(Number(UpdatedStReturn).toLocaleString(`us-EN`)) 
      }
    }

    if (selectedOption === 'Gold Plan') {
      if (Amount < 15000 || Amount > 24999) {
        toast.error('Please input a valid figure between 15000 and 24999', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        updateExpReturn()
        return updatePlanAmount({amount: ""})
      }else{
        const StCalc = (Amount *  (1.60 / 100) * 60).toFixed(2);
        const UpdatedStReturn = (Number(Amount) + Number(StCalc)).toFixed(2);
        return updateExpReturn(Number(UpdatedStReturn).toLocaleString(`us-EN`)) 
      }
    }

    if (selectedOption === 'Platinum Plan') {
      if (Amount < 25000 || Amount > 100000) {
        toast.error('Please input a valid figure between 25000 and 100000', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        updateExpReturn();
        return updatePlanAmount({amount: ""})
      }else{
        const StCalc = (Amount *  (2.30 / 100) * 60).toFixed(2);
        const UpdatedStReturn = (Number(Amount) + Number(StCalc)).toFixed(2);
        return updateExpReturn(Number(UpdatedStReturn).toLocaleString(`us-EN`)) 
      }
    }
  }

  //select and start trading plan ------------------
  const [planType, updatePlanType] = useState('');
  const [ActiveAmountCard, updateActiveAmountCard] = useState(null);
  const [amountCardForm, updateAmountCardForm] = useState({
    amount: "",
    plantype: '',
  })
  const [isLoadingBtn, updateisLoadingBtn] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const SubmitTradeForm = async () =>{
    updateisLoadingBtn(true)
    setIsButtonDisabled(true)

    try {
        const tradePost =  await axios.post('/api/user/trade', {...amountCardForm}, userConfig)
        const tradeData = tradePost.data;   

        //checks if data contains any errors
        if(tradeData.errMsg){
            toast.error(tradeData.errMsg)
            updateAmountCardForm({...amountCardForm, amount: ''})
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
            setTimeout(() => {
              navigate('/user/deposit')
            }, 7000);
        }else{
            toast.success(tradeData.successMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
            });
            updateAmountCardForm({
                amount: '',
            })
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }

    } catch (error) {
        console.log(error)
        updateisLoadingBtn(false)
        setIsButtonDisabled(false)
    }
  }

  const validatePlanForm = () => {
    const Amount = amountCardForm.amount;

    if(!Amount){
      toast.error('Please input amount', {
        position:"top-center",
        autoClose: 3000,
        hideProgressBar:false,
        newestOnTop:true,
        closeOnClick:true,
        rtl:false,
        pauseOnFocusLoss:false,
        draggable:false,
        pauseOnHover: false,   
      });
      return updateAmountCardForm({amount: ""})
    }

    if (planType === 'Standard Trading') {
      if (Amount < 1000 || Amount > 4999) {
        toast.error('Please input a valid figure between 1000 and 4999', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        return updateAmountCardForm({amount: ""})  
      }else{
        SubmitTradeForm()
      }
    }

    if (planType === 'Silver Trading') {
      if (Amount < 5000 || Amount > 14999) {
        toast.error('Please input a valid figure between 5000 and 14999', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        return updateAmountCardForm({amount: ""})  
      }else{
        SubmitTradeForm()
      }
    }

    if (planType === 'Gold Trading') {
      if (Amount < 15000 || Amount > 24999) {
        toast.error('Please input a valid figure between 15000 and 24999', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        return updateAmountCardForm({amount: ""})  
      }else{
        SubmitTradeForm()
      }
    }

    if (planType === 'Platinum Trading') {
      if (Amount < 25000 || Amount > 100000) {
        toast.error('Please input a valid figure between 25000 and 100000', {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
        });
        return updateAmountCardForm({amount: ""})  
      }else{
        SubmitTradeForm()
      }
    }    
  }
  


  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>

        {/* pop up message */}
        {ActiveAmountCard && <div className='absolute w-full min-h-[100%] top-0 left-0 pt-[5rem] flex justify-center items-start bg-[#111111c9] z-50'>
            <div className='relative min-h-[360px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-black p-4 border border-black rounded-md sm:w-[350px] ssm:w-[80%]'>
                <div className='relative w-full flex justify-end items-center'>
                    <button onClick={()=> {updateActiveAmountCard(false); updateAmountCardForm({amount: ''})}} className="relative "  >
                        <ImCancelCircle className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1]' />
                    </button>
                </div>
                {/* all toast messages */}
                <div className=' w-full flex justify-center items-center '>
                    <ToastContainer          
                    />
                </div>

                <h3 className='relative w-full text-center text-[#A9ABAD] text-[15.30px] font-[550] pt-[0.50rem] pb-[2rem]'>Enter amount for <span className='text-[#61748f]'>{planType}</span> </h3>

                <div className="relative w-full min-h-[54px] flex space-x-2 items-center">
                  <p className='text-[20px] text-[#A9ABAD]'>$</p>
                  <input value={amountCardForm.amount} onBlur={()=> updateAmountCardForm({...amountCardForm, plantype:planType})} onChange = {(e)=> {updateAmountCardForm({...amountCardForm, amount: e.target.value}) }} type="number" name = "amount" className="text-center appearance-none no-number-arrows custom-holder w-[100%] h-[60px] bg-inherit px-[5px] border-b border-[#3b424c] rounded-md focus:outline-none  focus:border-b-[#A9ABAD]" placeholder="Enter Amount" />
                </div>

                <div className='w-full flex justify-center items-center pt-[1rem]'>
                  <button disabled = {isButtonDisabled} onClick={()=> {validatePlanForm()}} className='w-[100px] flex justify-center items-center space-x-2 text-[#A9ABAD] min-h-[40px] text-[14px] px-2 py-2 bg-inherit border border-[#66768a] rounded-sm transition-all ease-in duration-300 hover:border-[#7990ae] hover:text-white'>   
                      {isLoadingBtn ? <img src={spinner} className='w-[30px] h-[30px]' alt='loading'  /> : <p>Trade</p>} 
                  </button>
                </div>

            </div>
        </div>}
        
        <UserSideNav hideNav={hideNav} logout = '/signin' logoutBtn = {clearSess} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} logouticon = {<BiLogOut className='cursor-pointer text-[20px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>} />
        
        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <nav className="relative w-full min-h-[100px] flex justify-center items-start">    
                  <div className="relative w-full min-h-[50px] flex justify-between items-center">
                      {/* <!-- hamburger menu --> */}
                      <button onClick={() => {updateNavActive(true)} } className="relative">
                          <GiHamburgerMenu className='text-[20px] text-[#A9ABAD]' />
                      </button>
                      
                      {/* <!-- user balance--> */}
                      <div className='relative flex space-x-2'>
                          <div  className='relative flex justify-center items-center space-x-1 w-[120px] min-h-[38px] py-2 px-4 border border-[#2e1362] rounded-[5px] transition-all ease-in duration-200 bg-inherit hover:bg-[#5f36a2] hover:border-[#5f36a2]'>
                              <BiSolidWallet className='text-[15px] text-[#A9ABAD]'/>
                              <p className='text-[12px] text-[#A9ABAD] text-ellipsis'>
                                  ${allUserMongoData && allUserMongoData.balance ? allUserMongoData.balance.toLocaleString() : '0' }
                              </p>
                          </div>
                      </div>
                  </div>
            </nav> 

            {/* header and paragraph texts */}
            <div className='relative pt-[2rem] w-full flex flex-col justify-center items-center space-y-2'>
                <h3 className='text-center'>Select Trading Plan</h3>
                <p className='text-center text-[#A9ABAD] text-[15px]'>Select a 30-day trading plan within your capital</p>
            </div>

            {/* plan boxes */}
            <div className='relative w-[100%] min-h-[300px] flex flex-col justify-center items-center space-y-6 py-8 md:space-y-0 md:min-w-[600px] md:flex-row md:space-x-4 xl:min-w-[900px]'>
                
                <div className='w-[100%] min-h-[100px] bg-[#181C26] border border-[#181C26] rounded-md flex flex-col justify-start items-center space-y-1 px-4 py-6 ssm:w-[90%] sm:w-[420px]'>
                    <h5 className='text-[17px] text-center text-[#acafb3]'>Standard Trading</h5>
                    <p className='text-[14px] text-[#838486] text-center'>This automated trading plan offers a 0.70% daily profit for the trade duration.</p>

                    <div className='w-full flex flex-col justify-center items-center space-y-6 pt-[1rem]'>
                        <p className='tracking-widest text-center text-[#edecec]'>$1,000 - $4,999</p>

                        <button onClick={()=>{ updatePlanType('Standard Trading'); updateActiveAmountCard(true); window.scrollTo(0,0);}} className='max-w-[300px] min-h-[40px] px-4 flex justify-center items-center space-x-2 box-shadowTradeBtn bg-inherit border border-[#3b424c] rounded-sm'>
                          <p className='text-[14px] text-[#95989e]'>Get Started</p>
                          <BsFillArrowRightCircleFill className='text-[14px] text-[#3b424c]'/>
                        </button>
                    </div>
                </div>

                <div className='w-[100%] min-h-[100px] bg-[#181C26] border border-[#181C26] rounded-md flex flex-col justify-start items-center space-y-1 px-4 py-6 ssm:w-[90%] sm:w-[420px]'>
                    <h5 className='text-[17px] text-center text-[#acafb3]'>Silver Trading</h5>
                    <p className='text-[14px] text-[#838486] text-center'>This automated trading plan offers a 1.10% daily profit for the trade duration.</p>

                    <div className='w-full flex flex-col justify-center items-center space-y-6 pt-[1rem]'>
                        <p className='tracking-widest text-center text-[#edecec]'>$5,000 - $14,999</p>

                        <button onClick={()=>{ updatePlanType('Silver Trading'); updateActiveAmountCard(true); window.scrollTo(0,0); }} className='max-w-[300px] min-h-[40px] px-4 flex justify-center items-center space-x-2 box-shadowTradeBtn bg-inherit border border-[#3b424c] rounded-sm'>
                          <p className='text-[14px] text-[#95989e]'>Get Started</p>
                          <BsFillArrowRightCircleFill className='text-[14px] text-[#95989e]'/>
                        </button>
                    </div>
                </div>

                <div className='w-[100%] min-h-[100px] bg-[#181C26] border border-[#181C26] rounded-md flex flex-col justify-start items-center space-y-1 px-4 py-6 ssm:w-[90%] sm:w-[420px]'>
                    <h5 className='text-[17px] text-center text-[#acacaa]'>Gold Trading</h5>
                    <p className='text-[14px] text-[#A9ABAD] text-center'>This automated trading plan offers a 1.60% daily profit for the trade duration.</p>

                    <div className='w-full flex flex-col justify-center items-center space-y-6 pt-[1rem]'>
                        <p className='tracking-widest text-center text-[#edecec]'>$15,000 - $24,999</p>

                        <button onClick={()=>{ updatePlanType('Gold Trading'); updateActiveAmountCard(true); window.scrollTo(0,0); }} className='max-w-[300px] min-h-[40px] px-4 flex justify-center items-center space-x-2 box-shadowTradeBtn bg-inherit border border-[#3b424c] rounded-sm'>
                          <p className='text-[14px] text-[#95989e]'>Get Started</p>
                          <BsFillArrowRightCircleFill className='text-[14px] text-[#3b424c]'/>
                        </button>
                    </div>
                </div>

                <div className='w-[100%] min-h-[100px] bg-[#181C26] border border-[#181C26] rounded-md flex flex-col justify-start items-center space-y-1 px-4 py-6 ssm:w-[90%] sm:w-[420px]'>
                    <h5 className='text-[17px] text-center text-[#bfc1c4]'>Platinum Trading</h5>
                    <p className='text-[14px] text-[#A9ABAD] text-center'>This automated trading plan offers a 2.30% daily profit for the trade duration.</p>

                    <div className='w-full flex flex-col justify-center items-center space-y-6 pt-[1rem]'>
                        <p className='tracking-widest text-center text-[#edecec]'>$25,000 - $100,000</p>

                        <button onClick={()=>{ updatePlanType('Platinum Trading'); updateActiveAmountCard(true); window.scrollTo(0,0); }} className='max-w-[300px] min-h-[40px] px-4 flex justify-center items-center space-x-2 box-shadowTradeBtn bg-inherit border border-[#3b424c] rounded-sm'>
                          <p className='text-[14px] text-[#95989e]'>Get Started</p>
                          <BsFillArrowRightCircleFill className='text-[14px] text-[#95989e]'/>
                        </button>
                    </div>
                </div>

            </div>

            {/* profit calculator */}
            <div className='relative pt-[2rem] w-full flex flex-col justify-center items-center space-y-2'>
                <h3 className='text-center'>Profit calculator</h3>
                <p className='text-center text-[#A9ABAD] text-[15px] pb-[1rem]'>Calculate your potential earnings with our profit calculator.</p>
                
                {/* all toast messages */}
                {!ActiveAmountCard && <div className='relative w-full flex justify-center items-center ssm:w-[90%] sm:w-[420px]'>
                    <ToastContainer          
                      className="relative w-full "
                    />
                </div>}

                <div className='w-[100%] min-h-[250px] bg-[#181C26] border border-[#181C26] rounded-md flex flex-col justify-start items-center space-y-1 py-6 ssm:w-[90%] sm:w-[420px]'>
                
                  <button type='button' onClick={toggleDropdown} className='w-[150px] bg-inherit text-white text-[13px] flex justify-center items-center space-x-4'>
                      <p className='text-[15px] text-white text-ellipsis'>{selectedOption}</p>  
                      <BsChevronDown className='text-[#868e98] text-[14px] flex-shrink-0'/>
                  </button>

                  {isOpen && (
                  <div className='relative w-full '>
                      <ul className='absolute max-w-[200px] top-[0px] z-50 left-[20%] bg-[rgb(0,0,0,0.80)] border border-black rounded-md ssm:w-[200px] ssm:left-1/4'>
                          <li onClick={() => {handleOptionClick('Standard Plan'); updatePlanAmount({amount: ""}); updateExpReturn()}}
                            className='px-4 text-white text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'> 
                            Standard Plan
                          </li>

                          <li onClick={() => {handleOptionClick('Silver Plan'); updatePlanAmount({amount: ""}); updateExpReturn() }}
                            className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'> 
                            Silver Plan
                          </li>

                          <li onClick={() => {handleOptionClick('Gold Plan'); updatePlanAmount({amount: ""}); updateExpReturn() }}
                            className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'> 
                            Gold Plan
                          </li>

                          <li onClick={() => {handleOptionClick('Platinum Plan'); updatePlanAmount({amount: ""}); updateExpReturn(); }}
                            className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'> 
                            Platinum Plan
                          </li>
                      </ul>
                  </div>
                  )}

                  {/* profit calculator */}
                  <div className="relative px-5 pt-[3rem] w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3">
                    <div className="relative w-full min-h-[54px]">
                      <p className="absolute right-[10px] top-[35%] text-[#8094ae] font-[550] text-[13px] font-dashheaderfont leading-[19px] pl-[10px] text-headerFont border-l-[1.5px] border-l-[#8094ae] md:text-[16px]">USD</p>
                      <input value={planAmount.amount} onFocus={()=> setIsOpen && setIsOpen(false)} onChange = {(e)=> {updatePlanAmount({amount: e.target.value}); }} type="number" name = "amount" className="appearance-none no-number-arrows custom-holder w-[100%] h-[60px] bg-inherit px-[5px] border-b border-[#3b424c] rounded-md focus:outline-none  focus:border-b-[#8094ae]" placeholder="Enter Amount" />
                    </div>


                    <div className='w-full flex justify-center items-center pt-[1rem]'>
                      <button onClick={()=> {doProfitCalc(); setIsOpen && setIsOpen(false);}} className='w-[100px] min-h-[40px] text-[14px] px-2 py-2 bg-inherit border border-[#69707a] rounded-sm'>
                          Calculate
                      </button>
                    </div>

                    <div onClick= {()=> setIsOpen(false)} className='relative w-full pt-[1rem] flex items-center justify-between'>
                      <p className='text-[#535963] text-[14px]'>Expected Return:</p>
                      <p className='text-[14px] text-[#e1e1e1]'>$ {expReturn}</p>
                    </div>
                  </div>

                </div>

            </div>

        </div>
    </div>
  )
}

export default Usertrade