import React from 'react'
import MobileMenu from './components/MobileMenu';
import Viewport from './components/Viewport';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom"
import { AiOutlineMenu } from "react-icons/ai";
import { FiLogIn } from "react-icons/fi";
import { BsEye } from "react-icons/bs";
import spinner from '../images/Spinnerblack.svg'

import mainLogo from '../images/LogoDark blue.svg'

import {BsWhatsapp} from 'react-icons/bs' 
import {MdEmail} from 'react-icons/md'
import { IoLogInOutline } from "react-icons/io5";


const Signin = () => {

  const { width } = Viewport();
  const [inactiveMobileNav, activeMobileNav] = useState(false)
  const breakpoint = 558;

  const navigate = useNavigate();

  //toggle password view
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  //signin form submit-------------------------------
  const [signinValues, setsigninValues] = useState({
    email: "",
    password: "",
  });

  const [isLoadingBtn, updateisLoadingBtn] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const signinSubmit = async (e) =>{
    e.preventDefault();
    updateisLoadingBtn(true)
    setIsButtonDisabled(true)

    try {
        //send form data to backend
        const signInResp =  await axios.post('/api/signin', {...signinValues})
        const signIndata = signInResp.data;

        //checks if data contains any errors
        if(signIndata.errMsg){
            toast.error(signIndata.errMsg, {
              position:"top-center",
              autoClose: 5000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
            });
            window.scroll(0, 0)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }else{
            if(signIndata.adminSuccessMsg){
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
                sessionStorage.setItem('adminInfo', JSON.stringify(signIndata.token))
                navigate('/admin/dashboard'); // Navigate to the admin dashboard
            }else{
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
                sessionStorage.setItem(`userInfo`, JSON.stringify(signIndata.token)) 
                navigate('/user/dashboard'); // Navigate to the user dashboard
            }          
        }

    }catch (error) {
        console.log(error)
        updateisLoadingBtn(false)
        setIsButtonDisabled(false)
    }      
  }
  

  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-start items-center bg-backgroundWhite text-textBlack w-full p-[1rem] min-h-screen ">
    
        {/* mobile menu */}
        <MobileMenu closeMobileMenu = {() => {activeMobileNav(false) }} translateAction = {inactiveMobileNav ? `translate-y-[0px]` : `translate-y-[-1500px]`} />          
        
        {/* nav section */}
        {<nav className="relative flex justify-between items-center min-h-[4rem] w-full md:w-[80%]">
            
          <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
            <img src = {mainLogo} alt="" className='h-[60%]' />
          </Link>


          {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
              <AiOutlineMenu className='text-black font-[500] text-[24px]'/>
              
          </button> : ''} 


          <div className="hidden justify-between items-center sm:flex">
                <ul className="flex space-x-10">
                  <li className="text-textBlack font-[600] text-[15.5px]"> <Link to = "/Signin">Trade</Link> </li>
                  <li className="text-textBlack font-[600] text-[15.5px]"> <Link to = "/about">About</Link> </li>
                  <li className="text-textBlack font-[600] text-[15.5px]"> <Link to = "/airdrops">Airdrops</Link> </li>
                </ul>
          </div>

        </nav>}                
        
        <div className='relative py-[7rem] min-h-[10rem] w-full flex flex-col justify-start items-center space-y-[2rem] md:w-[80%]'>
            
            <div className='relative'>
                <h1 className='text-textBlack text-[22px] font-[550] pb-[0.50rem]'>Welcome To XolarChain</h1>
                <p  className='text-textBlack pb-5'>Log into your account or <Link to = '/signup' className='text-[#252579]'>Sign Up</Link> to create an account. </p>
                <ToastContainer className="relative w-full sm:w-[450px]"/>
            </div>

            <form action="" className='w-full flex flex-col justify-center items-center' onSubmit={(e) => signinSubmit(e)}>
                  <div className='relative w-full flex flex-col justify-center items-center space-y-4'>

                      <div className='relative w-full flex flex-col justify-start items-center space-y-2 '>
                            
                            <div className='w-full flex justify-between sm:w-[450px]'>
                                <label htmlFor="email" className='text-[15px] text-left text-textBlack font-[550]'>Email/Username</label>
                            </div>

                            <input type="text" name='email' className='bg-inherit px-2 border-[#D3D3D3] border-[1px] rounded-[5px] w-full h-[45px] text-textBlack text-[16px] sm:w-[450px] focus:outline-none focus:border-[#e3e3e3] ' onChange={(e)=> setsigninValues({...signinValues, [e.target.name]:e.target.value})} />
                            
                            <div className='w-full flex justify-between sm:w-[450px]'>
                                <label htmlFor="password" className='text-[15px] text-left text-textBlack font-[550]'>Password</label>
                                <button type= "button" onClick={togglePasswordVisibility} className ='flex items-center justify-end text-[14px]'><BsEye className='text-textBlack mr-[5px] text-[16px]'/> {showPassword ? 'Hide' : 'Show'} </button> 
                            </div>
                            
                            <input 
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                className='bg-inherit px-2 border-[#D3D3D3] border-[1px] rounded-[5px] w-full h-[45px] text-textBlack text-[16px] sm:w-[450px] focus:outline-none focus:border-[#e3e3e3]'
                                onChange={(e)=> setsigninValues({...signinValues, [e.target.name]:e.target.value})}
                            />

                            <div className='w-full flex justify-end sm:w-[450px] '>
                                <Link className='text-[14px] text-[#67676b]' to = "/forgotpassword">Forgot password?</Link>
                            </div>
                      </div>

                      <button type='submit' disabled = {isButtonDisabled} className='bg-mainBackColor text-white border-[#8E8E93] rounded-md flex justify-center items-center text-center w-full h-[50px] transition-all sm:w-[450px] hover:bg-[#aeaeae]'>
                          {isLoadingBtn ? <img src={spinner} className='w-[50px] h-[50px]' alt='loading'  /> : <p>Sign In</p>} 
                      </button>
                  </div>
              </form>

        </div>

        <footer className='relative mt-[4rem] w-full min-h-[20rem] py-8 flex flex-col items-center justify-end space-y-[1rem]bg-[#dde2e614] '>
                        
              <div className='relative py-[2.80rem] px-[2.80rem] top-[-8rem] w-[90%] md:w-[70%] min-h-[267px] flex flex-col justify-center items-center space-y-6 bg-mainBackColor border-mainBackColor rounded-[6px]'>
                      <h3 className='text-white font-[650] text-[28px] text-center'>Start trading with us</h3>
                      <p  className='text-[#ACACB5] font-[650] text-[16px] text-center'>Begin your trading journey today and unlock a world of opportunities.</p>

                      <div className='flex flex-col justify-start items-center space-y-2 py-[1rem] sm:flex-row sm:space-y-0 sm:space-x-2'>
                          <a href='mailto:support@xolarchain.com' className="relative w-[110px] h-[45px] flex bg-[#4d4d5c] justify-center items-center rounded space-x-2">
                              <p  className=" text-[14.5px] text-white">Contact Us  </p>   
                          </a> 
                      </div>
              </div>
              
              <div className='w-full flex flex-col justify-end items-center space-y-4'>
                      <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
                          <img src = {mainLogo} alt="" className='h-[60%]' />
                      </Link>

                      <div className='flex flex-col items-center justify-center space-y-2'>
                          <p className='text-[14px] text-[#5F6377]'>Copyright © 2024 Xolarchain </p>
                      </div>
                      
                      <div className='flex justify-center items-center space-x-4'>
                          <button onClick={()=> console.log('unavailable')} className='text-[22px] text-[#5F6377]'><BsWhatsapp/></button>
                          <a href="mailto:support@xolarchain.com" className='text-[25px] text-[#5F6377]'><MdEmail/></a>
                      </div>
              </div>
                       
        </footer>

    </div>
  )
}

export default Signin