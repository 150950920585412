import React, { useContext } from 'react';
import axios from 'axios';
import { useState  } from 'react'

const AppContext = React.createContext();

const AppProvider = ({ children }) => {

    const [successMessagesDefault, successMessageUpdate] = useState('');
    
    //admin
    const [allUsersMongoData, updateallUsersMongoData] = useState([]);
    const [allUsersWithData, updateAllUsersWithData] = useState([])
    const [allUsersDepData, updateAllUsersDepData] = useState([]) 
    const [AllUsersTradeData, updateAllUsersTradeData] = useState([])

    //users
    const [allUserMongoData, updateallUserMongoData] = useState([]);
    const [allUserTrade, updateallUserTrade] = useState([]);
    const [allUserDep, updateallUserDep] = useState([]);
    const [allUserWithdraw, updateallUserWithdraw] = useState([]);


    const fetchAdminData = async () => {
      try {
        // Get the token from sessionStorage
        const adminToken = sessionStorage.getItem('adminInfo');

        // Include the token in the request headers
        const adminConfig = {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        };

        // Fetch all mongodb data for admin
        const adminResponse = await axios.get('/api/admin/dashboard', adminConfig);
        const adminData = adminResponse.data.user;

        updateallUsersMongoData(adminData);   
        
        //fetch all withdrawal data
        const adminWithResp = await axios.get('/api/admin/withdrawals', adminConfig);
        const adminWithData = adminWithResp.data.user;

        updateAllUsersWithData(adminWithData);

        //fetch all deposit data
        const adminDepResp = await axios.get('/api/admin/deposit', adminConfig);
        const adminDepData = adminDepResp.data.user;

        updateAllUsersDepData(adminDepData); 

        //fetch all trade data
        const adminTradeResp = await axios.get('/api/admin/trade', adminConfig);
        const adminTradeData = adminTradeResp.data.user;

        updateAllUsersTradeData(adminTradeData); 
        
      } catch (error) {
        console.log(error)
      }
    }

    const fetchUserData = async () => {
      try {
          const userToken = sessionStorage.getItem('userInfo');

          const userConfig = {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          };

          // Fetch all mongodb data for user
          const userResponse = await axios.get('/api/user/dashboard', userConfig);
          const userData = userResponse.data.user;

          updateallUserMongoData(userData);         
          
          // Fetch all trade data for user
          const usertraderesp = await axios.get('/api/user/gettradedata', userConfig);
          const userTradeData = usertraderesp.data.user;

          updateallUserTrade(userTradeData); 

          // Fetch all deposit data for user
          const userDepositresp = await axios.get('/api/user/getdepositdata', userConfig);
          const userDepData = userDepositresp.data.user;

          updateallUserDep(userDepData); 

          // Fetch all withdraw data for user
          const userWithResp = await axios.get('/api/user/getwithdrawdata', userConfig);
          const userWithdATA = userWithResp.data.user;

          updateallUserWithdraw(userWithdATA); 

      } catch (error) {
        console.log(error)
      }
    }



    return <AppContext.Provider value={{
      successMessageUpdate,
      successMessagesDefault,
      allUsersMongoData,
      allUsersWithData,
      allUsersDepData,
      fetchAdminData,
      AllUsersTradeData,
      allUserMongoData,
      allUserTrade,
      allUserDep,
      allUserWithdraw,
      fetchUserData
    }}>{children}</AppContext.Provider>;
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };