import React from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MobileMenu from './components/MobileMenu';
import Viewport from './components/Viewport';
import { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import spinner from '../images/Spinnerblack.svg'

import mainLogo from '../images/LogoWhite.svg'

const Signup = () => {

  const { width } = Viewport();
  const [inactiveMobileNav, activeMobileNav] = useState(false);
  const breakpoint = 558;

  const navigate = useNavigate();

  //signup form submit
  const [signupValues, setsignupValues] = useState({
    email: "",
    password: "",
    phone: "",
    authcode: "",
    username: "",
    cpassword: "",
  });
  const [isLoadingBtn, updateisLoadingBtn] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [errorMessagesDefault, errorMessageUpdate] = useState([]);

  const signupSubmit = async (e) => {
    
    e.preventDefault();
    updateisLoadingBtn(true)
    setIsButtonDisabled(true)

    try {
      //send form data to backend
      const regResponse =  await axios.post('/api/signup', {...signupValues,})
      const regResponseData = regResponse.data;

      //checks if data contains any errors
      if(regResponseData.errors){
          //update input field values to be displayed alongside errors
          setsignupValues({
              email: regResponseData.email,
              username: regResponseData.username,
              phone: regResponseData.phone,
              password: "",
              cpassword: "",
              authcode: "",     
          })

          //destructure error messages from json data
          const newErrorMessages = regResponseData.errors.map(error => error.msg);

          // Update with new error messages
          errorMessageUpdate([...newErrorMessages]);  
          window.scroll(0, 0)
          updateisLoadingBtn(false)
          setIsButtonDisabled(false)
      }else{
          //success message from json data
          const succRegMsg = regResponseData.successMsg;  
          window.scrollTo({
            top: 0,
            behavior: 'smooth' 
         });
          toast.success(succRegMsg, {
            position:"top-center",
            autoClose: 5000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
          })
          updateisLoadingBtn(false)
          setIsButtonDisabled(false)
          setsignupValues({
            email: "",
            password: "",
            phone: "",
            authcode: "",
            username: "",
            cpassword: "",
          })

          // Navigate to the Signin component
          setTimeout(()=>{
            navigate('/completesignup');  
          }, 5000)                 
      }                   
    } catch (error){
          console.log('error')
          updateisLoadingBtn(false)
          setIsButtonDisabled(false)
    }    
  } 

  //call function to display toast error messages 
  useEffect(() => {

    const displayErrorMessages = () => {
      errorMessagesDefault.forEach((errorMessage) => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position:"top-center",
                autoClose: 5000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
        }
      });
    };    

    displayErrorMessages();

  }, [errorMessagesDefault]);


  //password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const togglecPasswordVisibility = () => {
    setShowcPassword((prevShowPassword) => !prevShowPassword);
  };


  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full p-[1rem] min-h-screen ">
    
        {/* mobile menu */}
        <MobileMenu closeMobileMenu = {() => {activeMobileNav(false) }} translateAction = {inactiveMobileNav ? `translate-y-[0px]` : `translate-y-[-1500px]`} />          
        
        {/* nav section */}
        { <nav className="relative flex justify-between items-center min-h-[4rem] w-full md:w-[80%]">
            
          <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
            <img src = {mainLogo} alt="" className='h-[60%]' />
          </Link>

          {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
              <AiOutlineMenu className='text-white font-[500] text-[24px]'/>
              
          </button> : ''} 

          <div className="hidden justify-between items-center sm:flex">
                <ul className="flex space-x-10">
                  <li className="text-primaryGreen font-[500] text-[15.5px]"> <Link to = "/Signin">Trade</Link> </li>
                  <li className="text-primaryGreen font-[500] text-[15.5px]"> <Link to = "/about">About</Link> </li>
                  <li className="text-primaryGreen font-[500] text-[15.5px]"> <Link to = "/airdrops">Airdrops</Link> </li>
                </ul>
          </div>

        </nav>}                
        
        <div className='relative py-[7rem] min-h-[10rem] w-full flex flex-col justify-start items-start space-y-[2rem] md:w-[80%]'>
            
            <div className='relative w-full flex flex-col justify-start items-start'>
              <h1 className='text-white text-[22px] font-[550]'>Welcome To XolarChain</h1>
              <p className='text-white pb-[1rem]'>Create an account or <Link to = '/signin' className='text-primaryGreen'>log in</Link>. </p>
              <ToastContainer className="relative w-full sm:w-[450px]"/>
            </div>

            <form action="" onSubmit={(e)=> signupSubmit(e)}>
                <div className='relative w-full flex flex-col space-y-4'>
                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="email" className='text-[15px] text-left text-white font-[450]'>Email</label>
                        <input type="text" name='email' value = {signupValues.email} className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen' onChange = {(e) => setsignupValues({...signupValues, [e.target.name]:e.target.value})} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="username" className='text-[15px] text-left text-white font-[450]'>Username</label>
                        <input type="text" name='username' value = {signupValues.username} className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen' onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="phone" className='text-[15px] text-left text-white font-[450]'>Phone</label>
                        <input type="tel" name='phone' value = {signupValues.phone} className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen' onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="authcode" className='text-[15px] text-left text-white font-[450]'>Referral Code</label>
                        <input type="text" name='authcode' value = {signupValues.authcode} className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen' onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                      <div className='w-full flex justify-between sm:w-[450px]'>
                        <label htmlFor="password" className='text-[15px] text-left text-white font-[450]'>Password</label>
                        <button type='button' onClick = {togglePasswordVisibility} className ='flex items-center justify-end text-[14px]'><BsEye className='text-primaryGreen mr-[5px] text-[16px]'/>{showPassword ? 'Hide' : 'Show'}</button> 
                      </div>
                      
                        <input 
                          type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password' based on showPassword state
                          name='password'
                          value = {signupValues.password}
                          className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen' 
                          onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})}                  
                        />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <div className='w-full flex justify-between sm:w-[450px]'>
                          <label htmlFor="cpassword" className='text-[15px] text-left text-white font-[450]'>Confirm Password</label>
                          <button type='button' onClick={togglecPasswordVisibility} className ='flex items-center justify-end text-[14px]'><BsEye className='text-primaryGreen mr-[5px] text-[16px]'/>{showcPassword ? 'Hide' : 'Show'}</button> 
                        </div>

                        <input 
                          type={showcPassword ? 'text' : 'password'} // Toggle between 'text' and 'password' based on showPassword state
                          name='cpassword' 
                          value = {signupValues.cpassword}
                          className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen ' 
                          onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})}
                        />
                    </div>

                    <p className='text-[14px] text-[#5F6377] sm:w-[450px]'>By clicking the 'Sign Up' button, you are creating a Solarchain account, and you agree to Solarchains's <a href="#" className='text-primaryGreen'>Terms of Use </a>and <a href="#" className='text-primaryGreen'>Privacy Policy.</a></p>

                    <button  type='submit' disabled = {isButtonDisabled}  className='bg-primaryGreen text-white border-primaryGreen rounded-md flex justify-center items-center text-center w-full h-[50px] sm:w-[450px]'>
                       {isLoadingBtn ? <img src={spinner} className='w-[50px] h-[50px]' alt='loading'  /> : <p>Sign Up</p>} 
                    </button>

                </div>
            </form>
        </div>

    </div>
  )
}

export default Signup