import MobileMenu from './components/MobileMenu';
import Viewport from './components/Viewport';
import { useState, useEffect } from 'react';
import {Link} from "react-router-dom"
import { AiOutlineMenu } from "react-icons/ai";
import { FiLogIn } from "react-icons/fi";

import graphownblock from '../images/Graphic-Owned-Block-p-800.png'
import graphowncoins from '../images/Graphic-Owned-Coins-p-500.png'
import graphownplanets from '../images/Graphic-Owned-Planets-p-500.png'
import { IoLogInOutline } from "react-icons/io5";
import {BsWhatsapp} from 'react-icons/bs' 
import {MdEmail} from 'react-icons/md'

import mainLogo from '../images/LogoDark blue.svg'

const About = () => {

  const { width } = Viewport();

  const [inactiveMobileNav, activeMobileNav] = useState(false)

  const breakpoint = 558;

  const phoneNumber = '+447342518045';

  const openChat = () => {
    // Construct the WhatsApp chat link with the phone number
    const chatLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;

    // Open the chat link in a new window or tab
    window.open(chatLink, '_blank');
  };

  return (
    <div className='absolute overflow-x-hidden min-h-full w-full bg-backgroundWhite'>
        <div className="relative overflow-hidden min-h-full flex flex-col justify-start items-center text-white w-full p-[1rem]">
                
            {/* mobile menu */}
            <MobileMenu closeMobileMenu = {() => {activeMobileNav(false) }} translateAction = {!inactiveMobileNav ? `translate-y-[-1500px]`: `translate-y-[0px]`} />
            
            {/* nav section */}
            {<nav className="relative flex justify-between items-center min-h-[4rem] w-full md:w-[80%]">
                
                <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
                    <img src = {mainLogo} alt="" className='h-[60%]' />
                </Link>


                {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
                    <AiOutlineMenu className='text-black font-[500] text-[24px]'/>
                </button> : ''} 


                <div className="hidden justify-between items-center sm:flex">
                    <ul className="flex space-x-10">
                        <li className="text-textBlack font-[500] text-[15.5px]"> <Link to = "/Signin">Trade</Link> </li>
                        <li className="text-textBlack font-[500] text-[15.5px]"> <Link to = "/about">About</Link> </li>
                        <li className="text-textBlack font-[500] text-[15.5px]"> <Link to = "/airdrops">Airdrops</Link> </li>
                    </ul>
                </div>

                <Link to = "/signin" className="relative w-[110px] h-[38px] bg-[#6c859514] hidden justify-center items-center border border-[#757c8014] rounded space-x-2 sm:flex transition-all hover:border-[#1f262a14]">
                    <IoLogInOutline className='text-textBlack text-[16.5px] font-[500] '/>
                    <p  className=" text-[14.5px] text-textBlack">Sign in  </p>   
                </Link> 

            </nav>}

            {/* about us */}
            <div className='relative w-full min-h-[8rem] py-[6rem] flex flex-col justify-center items-center space-y-[9rem] md:w-[90%] lx:w-[85%]'>
                <div className='relative w-full py-[5rem] min-h-[14rem] flex flex-col space-y-[4rem] justify-center items-center lg:space-y-[0rem] lg:flex-row lg:justify-between lg:items-center'>
                    
                    {/* text */}
                    <div className='relative w-full flex flex-col items-center space-y-8 lg:w-[50%]'>
                        <div className='relative flex flex-col items-start space-y-5 lg:max-w-[500px]'>
                            <h2 className='font-[550] text-[28px] text-textBlack'>Built on web3. Owned by you.</h2>
                            <p className='text-[#696971] text-[16px] tracking-wide'>Welcome to Xolarchain, your trusted partner in the world of cryptocurrency trading. At Xolarchain, we are passionate about the transformative power of digital currencies and their potential to reshape the global financial landscape. As a leading cryptocurrency trading company, we strive to provide our clients with seamless access to the exciting and dynamic cryptocurrency markets. Our team of experienced traders, blockchain experts, and financial analysts work diligently to deliver cutting-edge trading solutions and valuable insights to our clients. Whether you are a seasoned investor or just starting your cryptocurrency journey, we are here to guide you every step of the way.</p>
                        </div>     
                    </div>


                    {/* image */}   
                    <div className='relative w-full flex justify-center items-center min-h-[300px] lg:w-[50%]'>  
                        <img src={graphownblock} alt="graphbox" loading='lazy' className='absolute top-0 left-[1/2] w-[356px] h-[356px] lg:w-[400px] lg:h-[350px]  '  /> 
                        <img src={graphownplanets} alt="graphbase" loading='lazy' className='absolute top-0 left-[1/2] w-[356px] h-[356px] lg:w-[400px] lg:h-[350px]  ' />
                        <img src={graphowncoins} alt="graphorbital" loading='lazy' className='absolute top-0 left-[1/2] w-[356px] h-[356px] lg:w-[400px] lg:h-[350px] ' />
                    </div> 
                                    
                </div>
            </div>


            <footer className='relative mt-[4rem] w-full min-h-[20rem] py-8 flex flex-col items-center justify-end space-y-[1rem]bg-[#dde2e614] '>
                        
                <div className='relative p-3 top-[-8rem] w-[90%] md:w-[70%] min-h-[267px] flex flex-col justify-center items-center space-y-6 bg-mainBackColor border-mainBackColor rounded-[6px]'>
                        <h3 className='text-white font-[650] text-[28px] text-center'>Start trading with us</h3>
                        <p  className='text-[#ACACB5] font-[650] text-[16px] text-center'>Begin your trading journey today and unlock a world of opportunities.</p>

                        <div className='flex flex-col justify-start items-center space-y-2 py-[1rem] sm:flex-row sm:space-y-0 sm:space-x-2'>
                            <Link to = "/signin" className="relative w-[110px] h-[45px] bg-[#3ac383] flex justify-center items-center rounded space-x-2 transition-all hover:border-[#3ac383]">
                                <IoLogInOutline className='text-white text-[18.5px] font-[500] '/>
                                <p  className=" text-[14.5px] text-white">Sign in  </p>   
                            </Link> 

                            <a href='mailto:support@xolarchain.com' className="relative w-[110px] h-[45px] flex bg-[#4d4d5c] justify-center items-center rounded space-x-2">
                                <p  className=" text-[14.5px] text-white">Contact Us  </p>   
                            </a> 
                        </div>
                </div>
                
                <div className='w-full flex flex-col justify-end items-center space-y-4'>
                        <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
                            <img src = {mainLogo} alt="" className='h-[60%]' />
                        </Link>

                        <div className='flex flex-col items-center justify-center space-y-2'>
                            <p className='text-[14px] text-[#5F6377]'>Copyright © 2024 Xolarchain </p>
                        </div>
                        
                        <div className='flex justify-center items-center space-x-4'>
                            <button onClick={()=> console.log('unavailable')} className='text-[22px] text-[#5F6377]'><BsWhatsapp/></button>
                            <a href="mailto:support@xolarchain.com" className='text-[25px] text-[#5F6377]'><MdEmail/></a>
                        </div>
                </div>
                       
            </footer>
            
        </div>
    </div>
  )
}

export default About