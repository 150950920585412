import MobileMenu from './components/MobileMenu';
import Viewport from './components/Viewport';
import { useState } from 'react';
import {Link} from "react-router-dom"



import graphicLeverage from '../images/Graphic-Leverage.png';
import GraphicPools from '../images/Graphic-Pools.png';
import GraphicTransactions from '../images/Graphic-Transactions.png';
import GraphicExecution from '../images/Graphic-Execution.png';
import iconreviews from '../images/Icon-Reviews.svg'
import iconsecure from '../images/Icon-Secure.svg'
import icondecent from '../images/Icon-Decentralized.svg'
import iconvets from '../images/Icon-Experience.svg'
import graphownblock from '../images/Graphic-Owned-Block-p-800.png'
import graphowncoins from '../images/Graphic-Owned-Coins-p-500.png'
import graphownplanets from '../images/Graphic-Owned-Planets-p-500.png'
import graphlayerto from '../images/Graphic-Layer-Top-p-800.png'
import graphlayercoins from '../images/Graphic-Layer-Coins.png'
import graphlayerbottoms from '../images/Graphic-Layer-Bottom-p-800.png' 
import iconinstant from '../images/Icon-Instant.svg'
import icondevice from '../images/Icon-Device.svg'
import interfacePhoto from '../images/interface.png'
import supportPhoto from '../images/support.png'
import airdropPhoto from '../images/gift-box.png'
import botPhoto from '../images/bot.png'

import {BsWhatsapp} from 'react-icons/bs' 
import {MdEmail} from 'react-icons/md'
import { AiOutlineMenu } from "react-icons/ai";
import { IoLogInOutline } from "react-icons/io5";
import { FaLongArrowAltRight } from "react-icons/fa";

import mainLogo from '../images/LogoDark blue.svg'


function Landing() {
    
  const { width } = Viewport();

  const [inactiveMobileNav, activeMobileNav] = useState(false)

  const breakpoint = 558;

  const phoneNumber = '+447342518045';

  const openChat = () => {
    // Construct the WhatsApp chat link with the phone number
    const chatLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;

    // Open the chat link in a new window or tab
    window.open(chatLink, '_blank');
  };

    return (
      
     
      <div className='absolute min-h-full overflow-x-hidden w-full bg-backgroundWhite'>
            <div className="relative h-full overflow-hidden flex flex-col justify-start items-center text-textBlack w-full ">
                    
                    {/* mobile menu */}
                    <MobileMenu closeMobileMenu = {() => {activeMobileNav(false) }} translateAction = {!inactiveMobileNav ? `translate-y-[-1500px]`: `translate-y-[0px]`} />
                    
                    {/* nav section */}
                    {<nav className="relative w-full flex justify-between items-center min-h-[4rem] py-[1rem] px-[1rem] md:px-[2rem]">
                        
                        <div className='w-full flex justify-between items-center space-x-[4rem]'>
                                <Link to = '/' className="relative flex justify-start items-center h-full w-[120px] shrink-0">
                                        <img src = {mainLogo} alt="Xolarchain" loading='lazy' className='h-[60%]' />
                                </Link>


                                {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
                                        <AiOutlineMenu className='text-textBlack font-[500] text-[24px]'/>
                                </button> : ''} 


                                <div className="hidden items-center space-x-[4rem] sm:flex">
                                    <ul className="flex space-x-[3rem]">
                                        <li className="text-textBlack font-[600] text-[15px]"> <Link to = "/Signin">Trade</Link> </li>
                                        <li className="text-textBlack font-[600] text-[15px]"> <Link to = "/about">About</Link> </li>
                                        <li className="text-textBlack font-[600] text-[15px]"> <Link to = "/airdrops">Airdrops</Link> </li>
                                    </ul>

                                    <div className='hidden justify-start items-center space-x-6 md:flex'>
                                        <Link to = "/signin" className="relative w-[110px] h-[45px] bg-[#6c859514] hidden justify-center items-center border border-[#757c8014] rounded space-x-2 sm:flex transition-all hover:border-[#1f262a14]">
                                                <IoLogInOutline className='text-textBlack text-[16.5px] font-[500] '/>
                                                <p  className=" text-[14.5px] text-textBlack">Sign in  </p>   
                                        </Link> 

                                        <Link to = "/signup" className="relative w-[110px] h-[45px] bg-mainBackColor hidden justify-center items-center rounded space-x-2 sm:flex transition-all hover:bg-[#0b0b2b]">
                                                <p  className=" text-[14.5px] text-white">Register  </p>   
                                        </Link> 
                                    </div>
                                </div>
                        </div>
                      
                    </nav>}

                    {/* showcase */}
                    <div className='relative w-full min-h-[35rem] flex justify-center items-center py-[1rem] px-[1rem] md:px-[2rem]'>
                        <div className='relative w-full min-h-full flex justify-center items-center py-[7rem]'>
                            <div className='relative flex flex-col justify-center items-start space-y-2 sm:items-center'>
                                <h4 className='text-[15px] text-textBlack font-[550] text-left sm:text-center'>BUILT ON WEB3. TRADE. EARN. BUILD</h4>
                                <h1 className='text-[30px] text-textBlack font-[550] text-left sm:text-center'>Trade with Precision and Stability</h1>
                                <p className='text-[16px] text-textBlack font-[350] text-left sm:text-center'>Upgrade your approach with our expert insights and analytics.</p>

                                <div className='relative w-full flex flex-col justify-start py-[2rem] space-y-4 ssm:flex-row ssm:space-x-2 ssm:space-y-0 sm:justify-center '>
                                    <Link to = "/signup" className="w-[140px] h-[43px] border border-[#3ac383] rounded flex justify-center items-center text-center text-white bg-[#3ac383] text-[15px] font-[500] transition-all hover:border-primaryGreen hover:bg-primaryGreen ">Start Trading</Link>
                                    <Link to = "/airdrops" className="w-[140px] h-[43px] bg-[#6c859514] border border-[#6c859514] rounded flex justify-center items-center text-center text-black text-[15px] font-[500] transition-all hover:border-[#1f262a14]">Airdrops</Link>
                                </div>
                            </div>
                        </div> 
                    </div>

                    {/* services */}
                    <div className='relative w-full min-h-[9rem] flex justify-center items-center px-[1rem] md:px-[2rem]'> 
                        <div className='relative w-[100%] min-h-[60px] flex flex-col justify-center items-center space-y-[4rem] border-t-[1px] border-t-[#141d221f] py-[4rem] sm:flex-row sm:space-x-[4rem] sm:space-y-0'>
                            <div className='flex space-x-2'>
                                    <p className='text-center text-[16px] text-textBlack'>Over 100,000+ active traders</p>
                            </div>

                            <div className='flex space-x-2'>
                                    <p className='text-center text-[16px] text-textBlack'>Algorithmic Trade Systems</p>
                            </div>

                            <div className='flex space-x-2'>
                                    <p className='text-center text-[16px] text-textBlack'>24/7 customer support</p>
                            </div>

                            <div className='flex space-x-2'>
                                    <p className='text-center text-[16px] text-textBlack'>Multiple regulatory licenses</p>
                            </div>
                        </div>   
                    </div>

                    {/* trade with capital protection */}
                    <div className='relative bg-mainBackColor w-full min-h-[10rem] py-[7rem] flex flex-col justify-center items-center space-y-[1rem] px-[1rem] md:px-[2rem]'>
                        <h2 className='text-[28px] text-white font-[500] text-center lg:text-[33px]'>Trade with Guaranteed Capital Protection</h2>
                        <p className='text-[#ACACB5] text-[17px] text-center max-w-700px]'>Safeguard your investments with advanced risk management tools and strategies.</p>

                        <div className='relative grid-container pt-[100px] max-w-[1628px] text-white'>
                            <div className=" flex flex-col justify-start items-center space-y-8 sm:max-w-[300px]">
                                <img src={graphicLeverage} alt="graphicLeverage" className='h-[50px] w-[50px]'  loading='lazy' />

                                <div className='flex flex-col space-y-2'>
                                    <p className="text-[17px] text-center font-[550] text-[#d4d4dc]">Leverage up to 30x</p>
                                    <p className='text-[15px] text-center font-[550] text-[#ACACB5]'>Amplify your trading potential with leverage, allowing you to control up to 30 times your capital.</p>
                                </div>
                               
                            </div>
                    
                            <div className="flex flex-col justify-start items-center  space-y-8 sm:max-w-[300px]">
                                <img src={GraphicPools} alt="GraphicPools" className='h-[50px] w-[50px]'  loading='lazy' />

                                <div className='flex flex-col space-y-2'>
                                    <p className="text-[17px] text-center font-[550] text-[#d4d4dc]">Instant withdrawals</p>
                                    <p className='text-[15px] text-center font-[550] text-[#ACACB5]'>Enjoy the convenience and security of having your money available when you need it, without any delays.</p>
                                </div>
                                
                            </div>
                            
                            <div className="flex flex-col justify-start items-center space-y-8 sm:max-w-[300px]">
                                <img src={GraphicTransactions} alt="GraphicTransactions" className='h-[50px] w-[50px]' loading='lazy' />

                                <div className='flex flex-col space-y-2'>
                                    <p className="text-[17px] text-center font-[550] text-[#d4d4dc]">Super cheap transactions</p>
                                    <p className='text-[15px] text-center font-[550] text-[#ACACB5]'>Enjoy minimal transaction fees, allowing you to maximize your profits.</p>
                                </div>
                            </div>
                            
                            <div className="flex flex-col justify-start items-center  space-y-8 sm:max-w-[300px]">
                                <img src={GraphicExecution} alt="GraphicExecution" className='h-[50px] w-[50px]'  loading='lazy' />

                                <div className='flex flex-col space-y-2'>
                                    <p className="text-[17px] text-center font-[550] text-[#d4d4dc]">Tight & stable spreads</p>
                                    <p className='text-[15px] text-center font-[550] text-[#ACACB5]'>Trade with confidence, knowing that our platform offers consistently tight and stable spreads.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* decentralized features */}
                    <div className='relative w-full min-h-[8rem] py-[6rem] flex flex-col justify-center items-center space-y-[9rem] md:w-[90%] lx:w-[85%] px-[1rem] md:px-[2rem]'>
                        
                        {/* container one */}
                        <div className='relative w-full min-h-[8rem] flex flex-col space-y-[4rem] justify-center items-center lg:space-y-[0rem] lg:flex-row lg:justify-between lg:items-center'>
                            
                            {/* text */}
                            <div className='relative w-full flex flex-col space-y-8 items-center lg:items-end lg:w-[50%]'>
                                <div className='relative flex flex-col space-y-5 lg:max-w-[500px]'>
                                    <h2 className='font-[550] text-[28px] text-textBlack'>Fully decentralized. Completely secure.</h2>
                                    <p className='text-textBlack text-[16px]'>Experience the highest level of security with our fully decentralized platform. Your assets and data are protected by blockchain technology, ensuring complete control and transparency.</p>
                                </div>

                                <div className='relative w-full grid gap-4 grid-cols-2 grid-rows-2 lg:max-w-[500px] '>
                                    <div className='relative flex justify-between items-center'>
                                        <p className='text-[14.50px] text-textBlack'>Secure trading platform</p>
                                    <img src={iconreviews} alt="iconreviews" loading='lazy' className='w-[40px] h-[40px]'/> 
                                    </div>

                                    <div className='relative flex justify-between items-center'>
                                        <p className='text-[14.50px] text-textBlack'>Your funds are as secure as possible</p>
                                    <img src={iconsecure} alt="iconreviews" loading='lazy' className='w-[40px] h-[40px]'/> 
                                    </div>

                                    <div className='relative flex justify-between items-center'>
                                        <p className='text-[14.50px] text-textBlack'>Fully decentralized and non-custodial</p>
                                    <img src={icondecent} alt="iconreviews" loading='lazy' className='w-[40px] h-[40px]'/> 
                                    </div>

                                    <div className='relative flex justify-between items-center'>
                                        <p className='text-[14.50px] text-textBlack'>Built by seasoned crypto veterans</p>
                                    <img src={iconvets} alt="iconreviews" loading='lazy' className='w-[40px] h-[40px]'/> 
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* container two */}
                        <div className='relative w-full min-h-[8rem] flex flex-col-reverse space-y-reverse-[14rem]  justify-center items-center lg:space-y-[0rem] lg:flex-row lg:justify-between lg:space-x-[2rem] lg:items-center'>
                            
                            {/* image */}   
                            <div className='relative w-full flex justify-center items-center min-h-[300px] pb-[4rem] lg:pb-[0] lg:w-[50%]'>  
                                <img src={graphownblock}   alt="graphbox"     loading='lazy'  className='absolute top-0 left-[1/2] w-[356px] h-[356px] lg:w-[400px] lg:h-[350px] lg:right-0'  /> 
                                <img src={graphownplanets} alt="graphbase"    loading='lazy'  className='absolute top-0 left-[1/2] w-[356px] h-[356px] lg:w-[400px] lg:h-[350px] lg:right-0' />
                                <img src={graphowncoins}   alt="graphorbital" loading='lazy'  className='absolute top-0 left-[1/2] w-[356px] h-[356px] lg:w-[400px] lg:h-[350px] lg:right-0' />
                            </div> 
                                            
                            
                            {/* text */}
                            <div className='relative w-full flex flex-col items-center space-y-8 lg:w-[50%]'>
                                <div className='relative flex flex-col items-start space-y-5 lg:max-w-[500px]'>
                                    <h2 className='font-[550] text-[28px] text-textBlack'>Built on web3.</h2>
                                    <p className='text-textBlack text-[16px]'>Leverage the power of the next generation internet with our platform, built on Web3 technology. Experience unparalleled decentralization, enhanced security, and true ownership of your assets.</p>
                                </div>     
                            </div>

                        </div>

                        {/* container three */}
                        <div className='relative w-full min-h-[8rem] flex flex-col space-y-[4rem] justify-center items-center lg:space-y-[0rem] lg:flex-row lg:justify-between lg:items-center'>
                            {/* text */}
                            <div className='relative w-full flex flex-col space-y-8 items-center lg:items-end lg:w-[50%]'>
                                <div className='relative w-full flex justify-start lg:max-w-[500px]'>
                                    <h2 className='font-[550] text-[28px] text-textBlack'>Anywhere, anytime.</h2>
                                </div>

                                <div className='relative w-full flex flex-col lg:max-w-[500px] '>
                                    <div className='relative flex justify-start items-center space-x-1'>
                                        <img src={iconinstant} alt="iconreviews" loading='lazy' className='w-[40px] h-[40px]'/> 
                                        <p className='text-[14.50px] text-textBlack'>Execute your trades instantly</p>
                                    </div>

                                    <div className='relative flex justify-start items-center space-x-1'>
                                        <img src={icondevice} alt="iconreviews" loading='lazy' className='w-[40px] h-[40px]'/> 
                                        <p className='text-[14.50px] text-textBlack'>Trade from any device</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                            {/* image */}   
                            <div className='relative w-full flex justify-center items-center min-h-[300px] lg:w-[50%]'>  
                                <img src={graphlayerto} alt="graphbox" loading='lazy' className='absolute top-0 left-[1/2] w-[356px] h-[251px] lg:w-[400px] lg:h-[300px] lg:right-[1/2] '  /> 
                                <img src={graphlayercoins} alt="graphbase" loading='lazy' className='absolute top-0 left-[1/2] w-[356px] h-[251px] lg:w-[400px] lg:h-[300px] lg:right-[1/2]' />
                                <img src={graphlayerbottoms} alt="graphorbital" loading='lazy' className='absolute top-0 left-[1/2] w-[356px] h-[251px] lg:w-[400px] lg:h-[300px] lg:right-[1/2]' />
                            </div>
                            
                        </div>

                    </div>

                    {/* features */}
                    <div className='relative w-full bg-mainBackColor min-h-[20rem] py-[6rem] flex flex-col justify-center items-center space-y-[4rem] px-[1rem] md:px-[2rem]'>
                        <div className='relative flex flex-col justify-start items-center space-y-5 lg:max-w-[500px]'>
                            <h2 className='font-[550] text-[28px] text-white'>Platform Features</h2>
                            <p className='text-[#d4d4dc] text-center text-[16px]'>Explore advanced tools and functionalities designed to enhance your trading experience.</p>
                        </div>

                        <div className='relative w-full pb-[4rem] flex flex-col space-y-4 sm:flex-none sm:space-y-0 sm:grid sm:gap-5 sm:w-[90%] md:grid-cols-2 lx:grid-cols-3 lg:w-[80%] '>           
                            <div className='relative max-w-[700px] min-h-[300px] bg-[#0C0D23] flex flex-col justify-between items-start space-y-4 p-[3rem] border border-[#0C0D23] rounded-md'>
                                <div className='w-[50px] h-[50px] flex justify-center items-center bg-[#F5F5F580] border-inherit rounded-[5px]'>
                                    <img src={interfacePhoto} alt='interface' className='w-[24px] h-[24px]' />
                                </div>

                                <div className='flex flex-col justify-start space-y-4'>
                                    <h3 className='text-[20px] text-white text-left font-[550]'>User-friendly interface</h3>
                                    <p className='text-[#878995] text-left'> Effortlessly manage your investments with clear, straightforward tools at your fingertips. </p>
                                </div>

                                <Link to = "/signup" className='flex items-center space-x-2 pt-4'>
                                    <p className='text-[15px] text-white'>Sign up</p>
                                    <FaLongArrowAltRight className='text-white text-[17px]'/>
                                </Link>
                            </div> 

                            <div className='relative max-w-[700px] min-h-[300px] bg-[#0C0D23] flex flex-col justify-between items-start space-y-4 p-[3rem] border border-[#0C0D23] rounded-md'>
                                <div className='w-[50px] h-[50px] flex justify-center items-center bg-[#F5F5F580] border-inherit rounded-[5px]'>
                                    <img src={airdropPhoto} alt='interface' className='w-[24px] h-[24px]' />
                                </div>

                                <div className='flex flex-col space-y-4'>
                                    <h3 className='text-[20px] text-white text-left font-[550]'>Airdrop Bonus Programs</h3>
                                    <p className='text-[#878995] text-left'>Take advantage of our exclusive Airdrop Bonus Programs, designed to reward you with tokens. </p>
                                </div>

                                <Link to= '/airdrops' className='flex items-center space-x-2 pt-4'>
                                    <p className='text-[15px] text-white'>Try now</p>
                                    <FaLongArrowAltRight className='text-white text-[17px]'/>
                                </Link>
                            </div> 

                            <div className='relative max-w-[700px] min-h-[300px] bg-[#0C0D23] flex flex-col justify-between items-start space-y-4 p-[3rem] border border-[#0C0D23] rounded-md'>
                                <div className='w-[50px] h-[50px] flex justify-center items-center bg-[#F5F5F580] border-inherit rounded-[5px]'>
                                    <img src={botPhoto} alt='interface' className='w-[24px] h-[24px]' />
                                </div>

                                <div className='flex flex-col space-y-4'>
                                    <h3 className='text-[20px] text-white text-left font-[550]'>Automated Trades</h3>
                                    <p className='text-[#878995] text-left'>Unlock the power of automated trading with our advanced bots. Simply select a plan, and our system executes trades in real-time to maximize your gains. </p>
                                </div>

                                <Link to = '/signup' className='flex items-center space-x-2 pt-4'>
                                    <p className='text-[15px] text-white'>Start trading</p>
                                    <FaLongArrowAltRight className='text-white text-[17px]'/>
                                </Link>
                            </div> 

                            <div className='relative max-w-[700px] min-h-[300px] bg-[#0C0D23] flex flex-col justify-between items-start space-y-4 p-[3rem] border border-[#0C0D23] rounded-md'>
                                <div className='w-[50px] h-[50px] flex justify-center items-center bg-[#F5F5F580] border-inherit rounded-[5px]'>
                                    <img src={supportPhoto} alt='interface' className='w-[24px] h-[24px]' />
                                </div>

                                <div className='flex flex-col space-y-4'>
                                    <h3 className='text-[20px] text-white text-left font-[550]'>24/7 Support</h3>
                                    <p className='text-[#878995] text-left'>Our dedicated team is available around the clock to assist you with any questions or issues, ensuring you receive timely and efficient help whenever you need it.</p>
                                </div>

                                <a href='mailto:support@xolarchain.com' className='flex items-center space-x-2 pt-4'>
                                    <p className='text-[15px] text-white'>Contact us</p>
                                    <FaLongArrowAltRight className='text-white text-[17px]'/>
                                </a>
                            </div> 

                        </div>
                    </div>


                    {/* FAQ */}
                    <div className='relative w-full min-h-[20rem] pt-[6rem] pb-[10rem] flex flex-col justify-center items-center space-y-[4rem] px-[1rem] md:px-[2rem]'>
                        
                        <h2 className='text-[29px] text-textBlack text-center'>FAQ</h2>
                        
                        <div className="w-full grid divide-y border-b border-b-neutral-200 max-w-[700px] mx-auto mt-8">
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> How do I purchase a plan?</span>
                                            <span className="transition group-open:rotate-180">
                                                 <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 text-[15px] group-open:animate-fadeIn">
                                            Subscribing to a plan is simple. Just sign up and log in to access your dashboard. From there, select the option to start trading, browse through the available trading plans, choose the one that suits you, and make the necessary payment to activate your plan.
                                        </p>
                                    </details>
                                </div>
                        </div>

                        <div className="w-full grid divide-y border-b border-b-neutral-200 max-w-[700px] mx-auto mt-8">
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> What are Airdrops?</span>
                                            <span className="transition group-open:rotate-180">
                                                 <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 text-[15px] group-open:animate-fadeIn">
                                            Airdrops constitute promotional initiatives wherein complimentary tokens or coins are allocated to a select cohort of eligible users. To ascertain your eligibility for our airdrops, kindly verify if you meet the specified criteria.
                                        </p>
                                    </details>
                                </div>
                        </div>

                        <div className="w-full grid divide-y border-b border-b-neutral-200 max-w-[700px] mx-auto mt-8">
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> How do I calculate my potential profit?</span>
                                            <span className="transition group-open:rotate-180">
                                                 <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 text-[15px] group-open:animate-fadeIn">
                                            We provide an integrated in-app profit calculator. After signing in, head to your dashboard and navigate to the Trade option to find this tool. The profit calculator helps you forecast potential earnings based on your chosen trading plan and investment amount.
                                        </p>
                                    </details>
                                </div>
                        </div>

                   </div>
                    
                    <footer className='relative mt-[4rem] w-full min-h-[20rem] py-8 flex flex-col items-center justify-end space-y-[1rem]bg-[#dde2e614] '>
                        
                        <div className='relative p-3 top-[-8rem] w-[90%] md:w-[70%] min-h-[267px] flex flex-col justify-center items-center space-y-6 bg-mainBackColor border-mainBackColor rounded-[6px]'>
                                <h3 className='text-white font-[650] text-[28px] text-center'>Start trading with us</h3>
                                <p  className='text-[#ACACB5] font-[650] text-[16px] text-center'>Begin your trading journey today and unlock a world of opportunities.</p>

                                <div className='flex flex-col justify-start items-center space-y-2 py-[1rem] sm:flex-row sm:space-y-0 sm:space-x-2'>
                                    <Link to = "/signin" className="relative w-[110px] h-[45px] bg-[#3ac383] flex justify-center items-center rounded space-x-2 transition-all hover:border-[#3ac383]">
                                        <IoLogInOutline className='text-white text-[18.5px] font-[500] '/>
                                        <p  className=" text-[14.5px] text-white">Sign in  </p>   
                                    </Link> 

                                    <a href='mailto:support@xolarchain.com' className="relative w-[110px] h-[45px] flex bg-[#4d4d5c] justify-center items-center rounded space-x-2">
                                        <p  className=" text-[14.5px] text-white">Contact Us  </p>   
                                    </a> 
                                </div>
                        </div>
                        
                        <div className='w-full flex flex-col justify-end items-center space-y-4'>
                                <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
                                    <img src = {mainLogo} alt="" className='h-[60%]' />
                                </Link>

                                <div className='flex flex-col items-center justify-center space-y-2'>
                                    <p className='text-[14px] text-[#5F6377]'>Copyright © 2024 Xolarchain </p>
                                </div>
                                
                                <div className='flex justify-center items-center space-x-4'>
                                    <button onClick={()=> console.log('unavailable')} className='text-[22px] text-[#5F6377]'><BsWhatsapp/></button>
                                    <a href="mailto:support@xolarchain.com" className='text-[25px] text-[#5F6377]'><MdEmail/></a>
                                </div>
                        </div>
                       
                    </footer>
                
            </div>
      </div>
    );
  }
  
  export default Landing;
  