import React from 'react'


import { IoIosClose } from "react-icons/io";
import { IoLogInOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

import mainLogo from '../../images/LogoDark blue.svg'

const MobileMenu = ({closeMobileMenu, translateAction}) => {

  return (

        <div className ={`fixed p-4 top-0 left-0 bg-backgroundWhite w-full h-full flex flex-col items-start justify-start space-y-[5rem] z-50 transition-all linear duration-[1000ms] ${translateAction}`}>

              <div className='relative flex justify-between items-center min-h-[4rem] w-full'>
                    <Link to = '/' className="relative flex justify-start items-center h-full w-[120px] shrink-0">
                        <img src = {mainLogo} alt="logo" className='h-[60%]' />
                    </Link>

                    <button onClick={() => closeMobileMenu()}>
                        <IoIosClose className='text-textBlack font-[550] text-[34px]' />
                    </button>  
              </div>
              

              
              <div className="relative w-full flex flex-col justify-start items-start">
                    <ul className="relative  w-full flex flex-col justify-start items-start space-y-[5rem]">
                        <li className="text-textBlack font-[500] text-[15.5px]"> <Link to = "/Signin">Trade</Link> </li>
                        <li className="text-textBlack font-[500] text-[15.5px]"> <Link to = "/about">About</Link> </li>
                        <li className="text-textBlack font-[500] text-[15.5px]"> <Link to = "/airdrops">Airdrops</Link> </li>

                        <Link to = "/signin" className="relative w-[110px] h-[45px] bg-[#6c859514] flex justify-center items-center rounded space-x-2 sm:flex transition-all hover:border-[#1f262a14]">
                            <IoLogInOutline className='text-textBlack text-[16.5px] font-[500] '/>
                            <p  className=" text-[14.5px] text-textBlack">Sign in  </p>   
                        </Link> 
                    </ul>
              </div>

        </div>


  )
}

export default MobileMenu