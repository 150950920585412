import React from 'react'
import MobileMenu from './components/MobileMenu';
import Viewport from './components/Viewport';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom"
import { AiOutlineMenu } from "react-icons/ai";
import { FiLogIn } from "react-icons/fi";
import { BsEye } from "react-icons/bs";
import spinner from '../images/Spinnerblack.svg'

import mainLogo from '../images/LogoWhite.svg'

const Resetpassword = () => {

  const { width } = Viewport();
  const [inactiveMobileNav, activeMobileNav] = useState(false)
  const breakpoint = 558;

  const navigate = useNavigate();

  //toggle password view
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };

  //signin form submit-------------------------------
  const [resetValues, setresetValues] = useState({
    recoverystring: "",
    password: "", 
    passwordtwo: "",
  });

  const [isLoadingBtn, updateisLoadingBtn] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const resetpasswordsubmit = async (e) =>{
    e.preventDefault();
    updateisLoadingBtn(true)
    setIsButtonDisabled(true)

    try {
        //send form data to backend
        const resetResp =  await axios.post('/api/resetpassword', {...resetValues})
        const resetData = resetResp.data;

        //checks if data contains any errors
        if(resetData.errMsg){
            toast.error(resetData.errMsg, {
              position:"top-center",
              autoClose: 5000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
            });
            window.scroll(0, 0)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }else{
          toast.success(resetData.successMsg, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
          })
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
            window.scroll(0, 0)
            setTimeout(() => {
              navigate('/signin'); // Navigate to the sign in
            }, 4000);
                 
        }

    }catch (error) {
        console.log(error)
        updateisLoadingBtn(false)
        setIsButtonDisabled(false)
    }      
  }


  return (
    //whole container
    <div className="relative overflow-x-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full p-[1rem] min-h-screen ">
    
        {/* mobile menu */}
        <MobileMenu closeMobileMenu = {() => {activeMobileNav(false) }} translateAction = {inactiveMobileNav ? `translate-y-[0px]` : `translate-y-[-1500px]`} />          
        
        {/* nav section */}
        {<nav className="relative flex justify-between items-center min-h-[4rem] w-full md:w-[80%]">
            
          <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
            <img src = {mainLogo} alt="" className='h-[60%]' />
          </Link>


          {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
              <AiOutlineMenu className='text-white font-[500] text-[24px]'/>
              
          </button> : ''} 


          <div className="hidden justify-between items-center sm:flex">
                <ul className="flex space-x-10">
                  <li className="text-primaryGreen font-[500] text-[15.5px]"> <Link to = "/Signin">Trade</Link> </li>
                  <li className="text-primaryGreen font-[500] text-[15.5px]"> <Link to = "/about">About</Link> </li>
                  <li className="text-primaryGreen font-[500] text-[15.5px]"> <Link to = "/airdrops">Airdrops</Link> </li>
                </ul>
          </div>



          <Link to = "/signin" className="relative w-[100px] h-[33px] hidden justify-center items-center rounded space-x-2 sm:flex">
              <FiLogIn className='text-white text-[17.5px] font-[500] '/>
              <p  className=" text-[15.5px] text-white">Sign In  </p>   
          </Link> 

        </nav>}                
        
        <div className='relative py-[7rem] min-h-[10rem] w-full flex flex-col justify-start items-start space-y-[2rem] md:w-[80%]'>
            
            <div className='relative'>
              <h1 className='text-white text-[22px] font-[550]'>Resest your XolarChain password</h1>
              <p className='text-[14px] text-[#A9ABAD] mb-[1rem] pt-[0.20rem]'>Check your email inbox for recovery pin</p>
              <ToastContainer className="relative w-full sm:w-[450px]"/>
            </div>

            <form action="" className='w-full' onSubmit={(e) => resetpasswordsubmit(e)}>
              <div className='relative w-full flex flex-col space-y-4'>
                  <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                      <label htmlFor="recoverystring" className='text-[15px] text-left text-white font-[450]'>Recovery Code</label>
                      <input value = {resetValues.recoverystring} 
                      type="text" name='recoverystring' 
                      className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen ' 
                      onChange={(e)=> setresetValues({...resetValues, [e.target.name]:e.target.value})} />
                  </div>

                  <div className='relative w-full flex flex-col justify-start items-start space-y-2 pt-[1rem]'>

                      <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                          <label htmlFor="password" className='text-[15px] text-left text-white font-[450]'>New Password</label>
                          <input value = {resetValues.password} type={showPassword ? 'text' : 'password'} name='password' className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen ' onChange={(e)=> setresetValues({...resetValues, [e.target.name]:e.target.value})} />
                      </div>

                      <div className='w-full flex justify-end sm:w-[450px]'>
                        <button type= "button" onClick={togglePasswordVisibility} className ='flex items-center justify-end text-[14px]'><BsEye className='text-primaryGreen mr-[5px] text-[16px]'/> {showPassword ? 'Hide' : 'Show'} </button> 
                      </div>

                      <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                          <label htmlFor="passwordtwo" className='text-[15px] text-left text-white font-[450]'>Confirm new password</label>
                          <input  value = {resetValues.passwordtwo}  type={showPassword2 ? 'text' : 'password'} name='passwordtwo' className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen ' onChange={(e)=> setresetValues({...resetValues, [e.target.name]:e.target.value})} />
                      </div>

                      <div className='w-full flex justify-end sm:w-[450px]'>
                        <button type= "button"  onClick={togglePasswordVisibility2} className ='flex items-center justify-end text-[14px]'><BsEye className='text-primaryGreen mr-[5px] text-[16px]'/> {showPassword2 ? 'Hide' : 'Show'} </button> 
                      </div>
                    </div>

                  <button type='submit' disabled = {isButtonDisabled} className='bg-primaryGreen text-white border-primaryGreen rounded-md flex justify-center items-center text-center w-full h-[50px] sm:w-[450px]'>
                    {isLoadingBtn ? <img src={spinner} className='w-[50px] h-[50px]' alt='loading'  /> : <p>Reset Password</p>} 
                  </button>
              </div>
            </form>

        </div>

    </div>
  )
}

export default Resetpassword