import React from 'react';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import ReactDataTable from '../components/ReactDataTable';
import { useGlobalContext } from '../../context';
import SideNav from '../components/SideNav';

import {AiOutlineCheck} from "react-icons/ai"
import {BsTrash} from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiLogOut} from "react-icons/bi" 
import {FiUsers} from "react-icons/fi"

const AdminWithdrawals = () => {

    const{allUsersWithData} = useGlobalContext();
  
    // Get the token from sessionStorage
    const adminToken = sessionStorage.getItem('adminInfo');
  
  
    //side nav
    const [navActive, updateNavActive] = useState(null)
  
    const clearSess = () =>{
        window.sessionStorage.clear();
    }
  
    const hideNav = () =>{
        updateNavActive(false)
    }
  
    const AdminSideNav = [
        { url: '/admin/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/admin/deposits', name: 'Manage Deposit', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/admin/trades', name: 'Manage Trades', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/admin/withdrawals', name: 'Manage Withdrawals', icon: <BiHistory className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/admin/users', name: 'Manage Users', icon: <FiUsers className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];

    const deleteWithdrawal = async (userEmail) =>{
        try {
            const response = await axios.delete('/api/admin/deletewithdrawal', {
                headers: {
                Authorization: `Bearer ${adminToken}`,
                },
                data: {
                userEmail: userEmail,
                },
            });
                const responseData = response.data;
        
                if(responseData.errMsg){
                    toast.error(responseData.errMsg, {
                    position:"top-center",
                    autoClose: 3000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
                }
                else{
                toast.success(responseData.successMsg, {
                    position:"top-center",
                    autoClose: 3000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
                }       
            } catch (error) {
                console.log(error)
            }
    }

    const confirmwithdrawal = async (userEmail) =>{
        try {
            const response = await axios.post('/api/admin/confirmwithdrawal', {userEmail}, {
                headers: {
                Authorization: `Bearer ${adminToken}`,
                },
            });
                const responseData = response.data;
        
                if(responseData.errMsg){
                    toast.error(responseData.errMsg, {
                    position:"top-center",
                    autoClose: 3000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
                }
                else{
                toast.success(responseData.successMsg, {
                    position:"top-center",
                    autoClose: 3000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
                }       
            } catch (error) {
                console.log(error)
            }
    }

    const declineWithdrawal = async (userEmail, amount, email) =>{
        try {
            const response = await axios.post('/api/admin/declinewithdrawal', {userEmail, amount, email}, {
                headers: {
                Authorization: `Bearer ${adminToken}`,
                },
            });
                const responseData = response.data;
        
                if(responseData.errMsg){
                    toast.error(responseData.errMsg, {
                    position:"top-center",
                    autoClose: 3000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
                }
                else{
                toast.success(responseData.successMsg, {
                    position:"top-center",
                    autoClose: 3000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
                }       
            } catch (error) {
                console.log(error)
            }
    }

    


    //table
    const columns = [
        {
            name: 'Date',
            selector: (row) => row.withdrawdate,
            cell: (row) => (
            <div style={{ whiteSpace: 'normal' }}>
                {new Date(row.withdrawdate).toLocaleDateString('en-GB')}
            </div>
            )
        },
        {
            name: 'Email', 
            selector: row => row.withdrawemail,
            cell: (row) =>(
                <div style={{whiteSpace:'normal'}}>
                    {row.withdrawemail}
                </div>
            )
        },
        {
        name: 'Amount', 
        selector: row => row.withdrawamount,
        cell: (row) =>{

            const amountNumber = parseFloat(row.withdrawamount); // Convert to number

            return (
                <div style={{whiteSpace:'normal'}}>
                    ${amountNumber.toLocaleString()}
                </div>
            );
        }
        },
        {
            name: 'Type', 
            selector: row => row.withdrawtype,
            cell: (row) =>(
                <div style={{whiteSpace:'normal'}}>
                    {row.withdrawtype}
                </div>
            )
        },
        {
            name: 'Status', 
            selector: row => row.withdrawstatus,
            cell: (row) =>(
                <div style={{whiteSpace:'normal'}}>
                    {row.withdrawstatus}
                </div>
            )
        },
        {
        name: 'Receipient', 
        selector: row => row.receiverdetails,
        cell: (row) =>(
            <div style={{whiteSpace:'normal'}}>
                {row.receiverdetails}
            </div>
        )
        },
        {
            name: 'Action', 
            cell: (row) =>(
                <div style={{whiteSpace:'normal', display:'flex', justifyItems: 'center', alignItems: 'center'}}>
                    <button onClick={() => deleteWithdrawal(row._id)} className="bg-red-700 mr-[20px] text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2"><BsTrash /></button>
                    <button onClick={() => declineWithdrawal(row._id, row.withdrawamount, row.withdrawemail)} className="bg-red-400 mr-[20px] text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2">X</button>
                    {row.withdrawstatus !== 'Declined' && <button onClick={() => confirmwithdrawal(row._id)} className="bg-green-700 text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2"><AiOutlineCheck /></button>}
                </div>
            )
        },
    ];
  
  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-black' textColor = 'text-white' links = {AdminSideNav}  />

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                </div>
            </div> 

            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>
                <p className='relative w-full text-center text-white'>Manage Withdrawals</p>
                
                {/* all toast messages */}
                <div className='relative w-full '>
                    <ToastContainer          
                      className="relative w-full sm:w-[450px]"
                    />
                </div>

                {/* table start */}
                <div className='relative max-w-full flex justify-center items-center '>
                    <ReactDataTable columns = {columns} data = {allUsersWithData} />   
                </div>
               

            </div>
        </div>

    </div>
  )
}

export default AdminWithdrawals