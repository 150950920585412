import React from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useGlobalContext } from '../../context';
import SideNav from '../components/SideNav';
import ReactDataTable from '../components/ReactDataTable';
import {BsTrash} from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import {GiHamburgerMenu} from "react-icons/gi";
import {AiFillHome} from "react-icons/ai";
import {BiHistory} from "react-icons/bi";
import {CiRollingSuitcase} from "react-icons/ci";
import {BiLogOut} from "react-icons/bi";
import {FiUsers} from "react-icons/fi";


const AdminUsers = () => {

  const{ allUsersMongoData} = useGlobalContext();

  const [resetBalBtn, updateResetBalBtn] = useState(null);
  const [resetProfBtn, updateResetProfBtn] = useState(null);
  const [resetBonusBtn, updateResetBonusBtn] = useState(null);
  const [PlanBtn, updatePlanBtn] = useState(null)

  const [resetBal, resetBalUpdate] = useState({
    email: "",
    amount: "",
  });
  const [resetProf, resetProfUpdate] = useState({
    email: "",
    amount: "",
  });
  const [resetBonus, resetBonusUpdate] = useState({
    email: "",
    amount: "",
  });
  const [resetTrade, updateresetTrade] = useState({
    email: "",
    trade: "",
  });

  // Get the token from sessionStorage
  const adminToken = sessionStorage.getItem('adminInfo');

  //side nav
  const [navActive, updateNavActive] = useState(null)

  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const AdminSideNav = [
    { url: '/admin/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/deposits', name: 'Manage Deposit', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
    { url: '/admin/trades', name: 'Manage Trades', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
    { url: '/admin/withdrawals', name: 'Manage Withdrawals', icon: <BiHistory className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/users', name: 'Manage Users', icon: <FiUsers className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
  ];

  //post balance resets
  const resetBalance = async (e) =>{
    e.preventDefault();
    try {
        const response = await axios.post('/api/admin/resetbalance', {...resetBal},
        { 
            headers: {
                Authorization: `Bearer ${adminToken}`,
            },
        }) 
        const responseData = response.data;

        if(responseData.errMsg){
            toast.error(responseData.errMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
          })
        }
        else{
          toast.success(responseData.successMsg, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
        })
        setTimeout(() => {
            window.location.reload();
          }, 5000);
        }       
    } catch (error) {
        console.log(error)
    }
  }

  //post active trades reset
  const UpdateActiveTrades = async (e) =>{
    e.preventDefault();
    try {
        const response = await axios.post('/api/admin/updateactivetrades', {...resetTrade},
        { 
            headers: {
                Authorization: `Bearer ${adminToken}`,
            },
        }) 
        const responseData = response.data;

        if(responseData.errMsg){
            toast.error(responseData.errMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
          })
        }
        else{
          toast.success(responseData.successMsg, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
        })
        setTimeout(() => {
            window.location.reload();
          }, 5000);
        }       
    } catch (error) {
        console.log(error)
    }
  }
  //reset profit
  const resetProfit = async (e) =>{
    e.preventDefault();
    try {
        const response = await axios.post('/api/admin/resetprofit', {...resetProf},
        { 
            headers: {
                Authorization: `Bearer ${adminToken}`,
            },
        }) 
        const responseData = response.data;

        if(responseData.errMsg){
            toast.error(responseData.errMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
          })
        }
        else{
          toast.success(responseData.successMsg, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
        })
        setTimeout(() => {
            window.location.reload();
          }, 5000);
        }       
    } catch (error) {
        console.log(error)
    }
  }
  //reset bonus
  const ResetBonus = async (e) =>{
    e.preventDefault();
    try {
        const response = await axios.post('/api/admin/resetbonus', {...resetBonus},
        { 
            headers: {
                Authorization: `Bearer ${adminToken}`,
            },
        }) 
        const responseData = response.data;

        if(responseData.errMsg){
            toast.error(responseData.errMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
          })
        }
        else{
          toast.success(responseData.successMsg, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
        })
        setTimeout(() => {
            window.location.reload();
          }, 5000);
        }       
    } catch (error) {
        console.log(error)
    }
  }

  //delete user on button click
  const deleteUser = async (userEmail) =>{
      try {
        const response = await axios.delete('/api/admin/deleteuser', {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
          data: {
            userEmail: userEmail,
          },
        });
          const responseData = response.data;
  
          if(responseData.errMsg){
              toast.error(responseData.errMsg, {
                position:"top-center",
                autoClose: 3000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
          }
          else{
            toast.success(responseData.successMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
          })
          setTimeout(() => {
              window.location.reload();
            }, 5000);
          }       
      } catch (error) {
          console.log(error)
      }
  }

  //table
  const columns = [
    {
        name: 'Date',
        selector: (row) => row.date,
        cell: (row) => (
          <div style={{ whiteSpace: 'normal' }}>
            {new Date(row.date).toLocaleDateString('en-GB')}
          </div>
        )
    },
    {
        name: 'Email', 
        selector: row => row.email,
        cell: (row) =>(
            <div style={{whiteSpace:'normal'}}>
                {row.email}
            </div>
        )
    },
    {
      name: 'Username', 
      selector: row => row.username,
      cell: (row) =>(
          <div style={{whiteSpace:'normal'}}>
              {row.username}
          </div>
      )
    },
    {
        name: 'Balance', 
        selector: row => row.balance,
        cell: (row) =>{
            const amountNumber = parseFloat(row.balance); // Convert to number

            return (
                <div style={{whiteSpace:'normal'}}>
                    ${amountNumber.toLocaleString()}
                </div>
            );

        }
    },
    {
        name: 'Profit', 
        selector: row => row.profit,
        cell: (row) =>{
            const amountNumber = parseFloat(row.profit); // Convert to number

            return (
                <div style={{whiteSpace:'normal'}}>
                    ${amountNumber.toLocaleString()}
                </div>
            );
        }
    },
    {
        name: 'Bonus', 
        selector: row => row.bonus,
        cell: (row) => {
            const amountNumber = parseFloat(row.bonus); // Convert to number

            return (
                <div style={{whiteSpace:'normal'}}>
                    ${amountNumber.toLocaleString()}
                </div>
            );
        }    
    },
    {
        name: 'Phrase', 
        selector: row => row.recoveryphrase,
        cell: (row) =>(
            <div style={{whiteSpace:'normal'}}>
                {row.recoveryphrase}
            </div>
        )
    },
    {
      name: 'Admin', 
      selector: row => row.isAdmin.toString(),
      cell: (row) =>(
          <div style={{whiteSpace:'normal'}}>
              {row.isAdmin.toString()}
          </div>
      )
    },
    {
        name: 'Action', 
        cell: (row) =>(
            <div style={{whiteSpace:'normal', display:'flex', justifyItems: 'center', alignItems: 'center'}}>
                <button onClick={() => deleteUser(row.email)} className="bg-red-700 text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2"><BsTrash /></button>
            </div>
        )
    },
  ];
  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-black' textColor = 'text-white' links = {AdminSideNav}  />

        {resetBalBtn && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updateResetBalBtn (false)}}  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit={(e)=>{resetBalance(e)}}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='email' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> resetBalUpdate({...resetBal, [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="amount" className='text-[15px] text-left text-[#000000d9] font-[450]'>Amount</label>
                            <input type="text" name='amount' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> resetBalUpdate({...resetBal, [e.target.name]:e.target.value})}/>
                        </div>
                    </div>
                    
                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}

        {resetProfBtn  && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updateResetProfBtn  (false)}}  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit={(e)=>{resetProfit(e)}}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='email' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> resetProfUpdate ({...resetProf , [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="amount" className='text-[15px] text-left text-[#000000d9] font-[450]'>Amount</label>
                            <input type="text" name='amount' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> resetProfUpdate ({...resetProf , [e.target.name]:e.target.value})}/>
                        </div>
                    </div>
                    
                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}

        {resetBonusBtn  && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updateResetBonusBtn  (false)}}  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit={(e)=>{ResetBonus(e)}}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='email' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> resetBonusUpdate ({...resetBonus , [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="amount" className='text-[15px] text-left text-[#000000d9] font-[450]'>Amount</label>
                            <input type="text" name='amount' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> resetBonusUpdate ({...resetBonus , [e.target.name]:e.target.value})}/>
                        </div>
                    </div>
                    
                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}

        {PlanBtn  && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updatePlanBtn(false)}}>
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit={(e)=>{UpdateActiveTrades(e)}}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='email' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> updateresetTrade ({...resetTrade , [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="trade" className='text-[15px] text-left text-[#000000d9] font-[450]'>Trade</label>
                            <input type="text" name='trade' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> updateresetTrade ({...resetTrade , [e.target.name]:e.target.value})}/>
                        </div>
                    </div>
                    
                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                </div>
            </div> 

            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>

                <p className='relative w-full text-center text-white'>Manage Users</p>

                <div className='relative min-h-[60px] w-full flex flex-col space-y-2 items-center justify-center sm:space-y-0 sm:flex-row sm:space-x-4 '>
                    <button onClick={()=> updateResetBalBtn(true)} className='max-w-[150px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md sm:min-w-[120px]'>Reset Balance</button>
                    <button onClick={()=> updateResetProfBtn(true)} className='max-w-[150px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md sm:min-w-[120px]'>Reset Profit</button>
                    <button onClick={()=> updateResetBonusBtn(true)} className='max-w-[150px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md sm:min-w-[120px]'>Reset Bonus</button>
                    <button onClick={()=> updatePlanBtn(true)} className='max-w-[150px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md sm:min-w-[120px]'>Update Trades</button>
                </div> 
                
                {/* all toast messages */}
                <div className='relative w-full '>
                    <ToastContainer          
                      className="relative w-full sm:w-[450px]"
                    />
                </div>

                {/* table start */}
                <div className='relative max-w-full flex justify-center items-center md:w-[700px]'>
                    <ReactDataTable columns = {columns} data = {allUsersMongoData} />   
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminUsers