import React from 'react'
import DataTable from "react-data-table-component"


const ReactDataTable = ({columns, data}) => {
 
    const paginationComponentOptions = {
        rowsPerPageText: false,
        rangeSeparatorText: 'of',
        selectAllRowsItem: false,
    };

    const paginationPerPage = 5

    const paginationRowsPerPageOptions = [5, 8, 10, 15, 20, 30]

    const customStyles = {

        rows: {
            style: {
                minHeight: '120px', // override the row height
                minWidth: '300px',
                borderRight: "none",
                '&:not(:last-of-type)': {
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '0.50px',
                    borderBottomColor: '#A9ABAD',
                },
            },
        },
        headCells: {
            style: {
                backgroundColor: 'black',
                color: 'white', 
                fontSize: '14px',
                fontWeight:'550px',
            },
        },
        cells: {
            style: {
                backgroundColor: 'black', 
                color: '#A9ABAD',
                minWidth: '150px'
            },
        },
        pagination: {
            style: {
              backgroundColor: 'black',
              color: 'white',
            },
            pageButtonsStyle: {
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: '8px',
                margin: 'px',
                cursor: 'pointer',
                transition: '0.4s',
                color: "white",
                fill: "white",
                backgroundColor: 'transparent',
                '&:disabled': {
                    cursor: 'unset',
                    color: "white",
                    fill: "white",
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: "white",
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: "white",
                },
            }
        },

        noData: {
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                textAlign: "center",
                backgroundColor: '#191919',
            },
        },
    };
    
  return (
    <div className='relative w-full min-h-[100px] '>
        <div className='w-full'>
            <DataTable className='overflow-x-auto'
                columns = {columns}
                data = {data}
                customStyles={customStyles}
                pagination 
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={paginationPerPage}
            />
        </div>
    </div>
  )
}

export default ReactDataTable