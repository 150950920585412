import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";


const SideNav = ({ hideNav, translateAction, backgroundColor, arrowColor, iconBorderColor, iconBgColor, textColor, links, SideNavuserName }) => {
  return (
    <div className={`fixed inset-y-0 left-0 h-full w-full ${backgroundColor} ${textColor} flex flex-col justify-start items-start z-50 transition-all ease-in duration-[500ms] ${translateAction} ssm:w-[320px] sm:w-[360px] md:w-[400px] xl:w-[450px]`}>
      <div className="relative w-full min-h-[80px] flex justify-between items-center px-5">
        {/* welcome message */}
        <div className='relative'>
          <p className='relative'>{`Welcome, ${SideNavuserName}`}</p>
        </div>

        <button className="relative flex justify-end items-center" onClick={hideNav}>
          <AiOutlineClose className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
        </button>
      </div>

      <div className="relative flex-grow w-full overflow-y-auto flex flex-col justify-evenly items-start space-y-16 px-5 pt-[3rem] pb-[1rem]">
        {links.map((link, index) => (
          <Link to={link.url} key={index} onClick={link.action && link.action} className="cursor-pointer flex w-full justify-between items-center">

            <div className="flex justify-start items-center space-x-4 transition-all ease-in duration-300 hover:translate-x-[10px]">
              <div className={`w-[35px] h-[35px] flex justify-center items-center ${iconBgColor} ${iconBorderColor} rounded-full`}>
                {link.icon}
              </div>

              <p className={`text-dashtextcolor font-dashheaderfont font-[200] text-[15.50px]`}>{link.name}</p>
            </div>

            <div>
              <MdArrowForwardIos className={`fa-solid fa-angle-right ${arrowColor} text-[#A9ABAD] text-[16px]`} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default SideNav