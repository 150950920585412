import React from 'react';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useGlobalContext } from '../../context';
import UserSideNav from '../components/UserSideNav';
import { Link } from 'react-router-dom';
import {GiHamburgerMenu} from "react-icons/gi"
import {AiFillHome} from "react-icons/ai" 
import {BsWalletFill} from "react-icons/bs"  
import {BiTransfer} from "react-icons/bi"  
import {BiLogOut} from "react-icons/bi" 
import {FaUsers} from "react-icons/fa"
import {BiHistory} from "react-icons/bi" 
import {MdCandlestickChart} from "react-icons/md"
import {BiSolidWallet} from "react-icons/bi"  
import {BsChevronDown} from "react-icons/bs" 
import {FaBitcoin} from "react-icons/fa" 
import {FaEthereum} from "react-icons/fa" 
import {BiSolidDollarCircle} from "react-icons/bi"
import spinner from '../../images/SpinnerTrans.svg'




const Usertransfer = () => {
  //universal variables --------------------- 
  const{allUserMongoData} = useGlobalContext();


  //window resize----------------------------
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  //get user header-------------------------
  const userToken = sessionStorage.getItem('userInfo');

  const userConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  //user side nav--------------------------
  const [navActive, updateNavActive] = useState(null)

  useEffect(()=>{
    if(navActive){
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
    }
  }, [navActive])
  
  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const userDashLinks = [
      { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/deposit', name: 'Deposit', icon: <BsWalletFill className='cursor-pointer text-[18px]  text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/user/trade', name: 'Trade', icon: <MdCandlestickChart className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transfer', name: 'Transfer', icon: <BiTransfer className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/kyc', name: 'KYC', icon: <FaUsers className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/user/transactions', name: 'Transactions', icon: <BiHistory className='cursor-pointer text-[22px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
  ];

  //wallet copy-----------------
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(                                        
    <li className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
        <FaBitcoin className='text-yellow-600 shrink-0' />
        <p>BTC</p> 
    </li>
  ); 



  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  //deposit submit-----------------

  const [TransferValues, setTransferValues] = useState({
    amount: '',
    walletaddress: '',
    cryptotype:  'BTC'
  })
  const [isLoadingBtn, updateisLoadingBtn] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const submitTransfer = async (e) => {
    e.preventDefault();
    updateisLoadingBtn(true)
    setIsButtonDisabled(true)

    try {
        const transferPost =  await axios.post('/api/user/transfer', {...TransferValues}, userConfig)
        const transferData = transferPost.data;   

        //checks if data contains any errors
        if(transferData.errMsg){
            toast.error(transferData.errMsg)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }else{
            toast.success(transferData.successMsg)  
            setTransferValues({
                amount: '',
                walletaddress: ''
            })
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }

    } catch (error) {
        console.log(error)
        updateisLoadingBtn(false)
        setIsButtonDisabled(false)
    }
  }



  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>
        
        <UserSideNav hideNav={hideNav} logout = '/signin' logoutBtn = {clearSess} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} logouticon = {<BiLogOut className='cursor-pointer text-[20px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>} />
        
        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <nav className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-[#A9ABAD]' />
                    </button>
                    
                    {/* <!-- user balance--> */}
                    <div className='relative flex space-x-2'>
                        <div  className='relative flex justify-center items-center space-x-1 w-[120px] min-h-[38px] py-2 px-4 border border-[#2e1362] rounded-[5px] transition-all ease-in duration-200 bg-inherit hover:bg-[#5f36a2] hover:border-[#5f36a2]'>
                            <BiSolidWallet className='text-[15px] text-[#A9ABAD]'/>
                            <p className='text-[12px] text-[#A9ABAD] text-ellipsis'>
                                ${allUserMongoData && allUserMongoData.balance ? allUserMongoData.balance.toLocaleString() : '0' }
                            </p>
                        </div>
                    </div>
                </div>
            </nav> 
                      
            <div className='relative w-full flex flex-col justify-center items-center space-y-8  pt-[6rem]'>
              
                <div className='w-full flex justify-center items-center space-x-4 sm:w-[400px]'> 
                    
                    <Link to='/user/deposit' className='max-w-[200px] min-h-[20px] py-2 px-5  flex justify-center items-center  '>
                        <p className='text-[13px]'>Deposit</p>
                    </Link>
                    
                    <div className='max-w-[200px] min-h-[20px] py-2 px-5 flex justify-center items-center bg-[rgb(24,28,38,0.70)] border border-black rounded-[9px]'>
                        <p className='text-[13px]'>Transfer</p>
                    </div>                  
                    
                </div>
                
                <form onSubmit={(e)=> submitTransfer(e)} className='relative w-full flex flex-col justify-center items-center space-y-8'>
                    <div className='w-full flex justify-center items-center space-x-1 min-h-[200px] sm:w-[400px]'>

                        <div className='relative w-full flex justify-center items-start space-x-1'>
                            <input type="text" 
                            value={TransferValues.amount.replace(/[^0-9$]/g, '')} 
                            name='amount' 
                            className='appearance-none no-number-arrows w-full text-center custom-placeholder-amount bg-inherit h-[52.5px] text-white text-[35px] focus:outline-none' placeholder='$0.00'
                            onBlur = {(e)=>{  setTransferValues({...TransferValues, [e.target.name]: e.target.value.startsWith('$') ? e.target.value : '$' + e.target.value}) }}
                            onChange={(e)=>{ setTransferValues({...TransferValues, [e.target.name]: e.target.value.replace('$', '')}) }} />
                        </div>
                        
                    </div>
                    
                    {windowWidth > 500 ? <ToastContainer/> : <ToastContainer className="relative w-full" />}

                    <div className='w-full h-full min-h-[130px] flex flex-col items-center justify-between bg-[#181C26] p-6 border border-black rounded-xl sm:w-[400px]'>
                        {/* currency type */}
                        <div className='relative w-full flex justify-between items-center '> 
                            <p className='text-[13px] text-[#868e98] w-[31%]'>Asset</p>

                            <div className='relative w-[69%]'>
                                <button type='button' onClick={toggleDropdown} className='w-full bg-inherit text-white text-[13px] flex justify-between items-center '>
                                    <div className='text-[13px] text-white text-ellipsis'>{selectedOption}</div>  
                                    <BsChevronDown className='text-[#868e98] text-[14px] flex-shrink-0'/>
                                </button>
                                {isOpen && (
                                    <ul className='absolute max-w-[200px] top-[-10px] z-50 left-[-5px] bg-black border border-black rounded-md ssm:w-[200px]'>
                                        <li onClick={() => {handleOptionClick(
                                            <li className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                                <FaBitcoin className='text-yellow-600 shrink-0' />
                                                <p>BTC</p> 
                                            </li>
                                        ); setTransferValues({...TransferValues, cryptotype: 'Bitcoin'})}}
                                        className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                            <FaBitcoin className='text-yellow-600 shrink-0' />
                                            <p>BTC</p> 
                                        </li>

                                        <li onClick={() => { handleOptionClick(
                                            <li className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                                <FaEthereum className='text-blue-400 text-[15px] shrink-0' />
                                                <p>ETH</p> 
                                            </li>
                                        ); setTransferValues({...TransferValues, cryptotype: 'ETH'})}}
                                        className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                            <FaEthereum className='text-blue-400 text-[15px] shrink-0' />
                                            <p>ETH</p> 
                                        </li>

                                        <li onClick={() => {handleOptionClick(
                                            <li className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                                <BiSolidDollarCircle className='text-[#868e98] text-[15px] shrink-0' />
                                                <p>USDT(ERC20)</p> 
                                            </li>
                                        ); setTransferValues({...TransferValues, cryptotype: 'USDT'}) }}
                                        className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                            <BiSolidDollarCircle className='text-[#868e98] text-[15px] shrink-0' />
                                            <p>USDT(ERC20)</p> 
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className='w-full h-[0.50px] bg-[#2B2B2B]'></div>

                        {/* address */}
                        <div className='relative w-full flex justify-center items-center space-x-2 '> 
                            <p className='text-[13px] h-full text-[#868e98] w-[35%]'>Wallet Address</p>

                            <div className='w-[65%] h-full flex '> 
                                <input type="text" value={TransferValues.walletaddress} name='walletaddress' className='custom-placeholder bg-inherit w-full h-full text-white text-[16px] focus:outline-none' placeholder='Enter wallet address here' onChange={(e)=> setTransferValues({...TransferValues, [e.target.name]:e.target.value})} />
                            </div>
                        </div>
                    </div>
                    
                    <div className='w-full sm:w-[400px] flex flex-col justify-center items-center space-y-2'>                 
                        <button type='submit' disabled = {isButtonDisabled}  className='relative  text-white text-[14px] w-full min-h-[40px] flex justify-center items-center space-x-2 p-2 border border-black rounded-md transition-all ease-in duration-200 bg-userDashPurple hover:bg-[#4239bc] sm:w-[400px]'>                    
                            {isLoadingBtn ? <img src={spinner} className='w-[30px] h-[30px]' alt='loading'  /> : <p className='text-[13px]'>Continue Transfer</p>  }                   
                        </button>
                    </div>
                </form>
         

            </div>            
      
        </div>   
    </div>
  )
}

export default Usertransfer