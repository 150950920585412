import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from "react-icons/ai";
import mainLogo from '../../images/LogoWhite.svg'

const UserSideNav = ({ hideNav, translateAction, backgroundColor, logouticon, logout, logoutBtn, iconBorderColor, iconBgColor, textColor, links }) => {
  return (
    <div className={`fixed inset-y-0 left-0 h-full w-full ${backgroundColor} ${textColor} flex flex-col justify-start items-start z-50 transition-all ease-in duration-[500ms] ${translateAction} border-r border-r-[#121212] ssm:w-[320px] sm:w-[360px] md:w-[400px] xl:w-[450px]`}>
        <div className="relative w-full min-h-[80px] flex justify-between items-center px-5">
            <div className="relative flex justify-start items-center h-full w-[50%]">
                <img src = {mainLogo} alt="Xolarchain" loading='lazy' className='h-[40px] w-[100px]' />  
            </div>

            <button className="relative flex justify-end items-center" onClick={hideNav}>
                <AiOutlineClose className='cursor-pointer text-[20px] text-[#A9ABAD] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[22px]' />
            </button>
        </div>

        <div className="relative flex-grow w-full overflow-y-auto flex flex-col justify-start items-start space-y-4 px-5 pt-[3rem] pb-[1rem]">
            {links.map((link, index) => (
            <Link to={link.url} key={index} onClick={link.action && link.action} className="cursor-pointer flex w-full justify-between items-center border border-black rounded-md p-2 hover:bg-[#0c0c0c]">
                <div className="flex justify-start items-center space-x-4 transition-all ease-in duration-300 hover:translate-x-[10px]">
                    <div className={`w-[35px] h-[35px] flex justify-center items-center ${iconBgColor} ${iconBorderColor} rounded-full`}>
                        {link.icon}
                    </div>

                    <p className={`text-[#A9ABAD] font-dashheaderfont font-[200] text-[15.50px]`}>{link.name}</p>
                </div>
            </Link>
            ))}
        </div>
        
        <div className='relative w-full px-5 pb-[1rem]'>
            <Link to={logout} onClick={logoutBtn} className="cursor-pointer flex w-[50%] justify-start items-center border border-black bg-[#0c0c0c] rounded-md p-1 md:w-[160px]">
                <div className="flex justify-start items-center space-x-2 transition-all ease-in duration-300 hover:translate-x-[10px]">
                    <div className={`w-[35px] h-[35px] flex justify-center items-center ${iconBgColor} ${iconBorderColor} rounded-full`}>
                        {logouticon}
                    </div>

                    <p className={`text-[#A9ABAD] font-dashheaderfont font-[200] text-[14.50px]`}>Sign Out</p>
                </div>
            </Link>
        </div>

    </div>
  )
}

export default UserSideNav