import React from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useGlobalContext } from '../../context';
import ReactDataTable from '../components/ReactDataTable';
import SideNav from '../components/SideNav';

import { ImCancelCircle } from "react-icons/im";
import {GiHamburgerMenu} from "react-icons/gi";
import {AiFillHome} from "react-icons/ai";
import {BiHistory} from "react-icons/bi";
import {CiRollingSuitcase} from "react-icons/ci";
import {BiLogOut} from "react-icons/bi";
import {FiUsers} from "react-icons/fi";


const AdminDeposits = () => {

  const{ allUsersDepData} = useGlobalContext();

  // Get the token from sessionStorage
  const adminToken = sessionStorage.getItem('adminInfo');

  //side nav
  const [navActive, updateNavActive] = useState(null)

  const clearSess = () =>{
      window.sessionStorage.clear();
  }

  const hideNav = () =>{
      updateNavActive(false)
  }

  const AdminSideNav = [
    { url: '/admin/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/deposits', name: 'Manage Deposit', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
    { url: '/admin/trades', name: 'Manage Trades', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
    { url: '/admin/withdrawals', name: 'Manage Withdrawals', icon: <BiHistory className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/users', name: 'Manage Users', icon: <FiUsers className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
  ];

  //update deposit button -------------------------------
  const [activedepForm, updateactiveDepForm] = useState(null);
  const [depositForm, UpdateDepForm] = useState({
    userId: "",
    depositAmount: "",
    depositType: "",
  });

  const depositSubmit = async (e) =>{
      e.preventDefault();
      try {
          const response = await axios.post('/api/admin/updatedeposit', {...depositForm},
          { 
              headers: {
                  Authorization: `Bearer ${adminToken}`,
              },
          }) 
          updateactiveDepForm(false)
          const responseData = response.data;
  
          if(responseData.errMsg){
              toast.error(responseData.errMsg, {
                position:"top-center",
                autoClose: 3000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
          }
          else{
            toast.success(responseData.successMsg, {
              position:"top-center",
              autoClose: 3000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
          })
          setTimeout(() => {
              window.location.reload();
            }, 5000);
          }

      } catch (error) {
          console.log(error)
      }
    }

    //update profit---------------------------
    const [ProfField, updateProfField] = useState(null)
    const [ProfForm, UpdateProfForm] = useState({
        userId: "",
        profamount: "",
    });

    const profSubmit = async (e) =>{
        e.preventDefault();
        try {
            const response = await axios.post('/api/admin/updateprof', {...ProfForm},
            { 
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            }) 
            updateProfField(false)
            const responseData = response.data;
    
            if(responseData.errMsg){
                toast.error(responseData.errMsg, {
                  position:"top-center",
                  autoClose: 3000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
              })
            }
            else{
              toast.success(responseData.successMsg, {
                position:"top-center",
                autoClose: 3000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
            setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
  
        } catch (error) {
            console.log(error)
        }
    }

    //update bonus---------------------------
    const [BonusField, updateBonusField] = useState(null)
    const [BonusForm, UpdateBonusForm] = useState({
        userId: "",
        BonusAmount: "",
    });

    const bonusSubmit = async (e) =>{
        e.preventDefault();
        try {
            const response = await axios.post('/api/admin/updatebonus', {...BonusForm},
            { 
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            }) 
            updateBonusField(false)
            const responseData = response.data;
    
            if(responseData.errMsg){
                toast.error(responseData.errMsg, {
                  position:"top-center",
                  autoClose: 3000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
              })
            }
            else{
              toast.success(responseData.successMsg, {
                position:"top-center",
                autoClose: 3000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
            setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
  
        } catch (error) {
            console.log(error)
        }
    }

    //delete deposit on button click
    const deleteDeposit = async (userId) =>{

        try {
            const response = await axios.delete('/api/admin/deletedeposit',
            { 
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },

                data: {
                    userId:userId,
                },
            });

            const responseData = response.data.successMsg;

            toast.success(responseData, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false, 
            })

            setTimeout(() => {
            window.location.reload();
            }, 5000);

        } catch (error) {
            console.log(error)
        }
    }


    //data table-----------------------------------------
    const columns = [
        {
            name: 'Date',
            selector: (row) => row.date,
            cell: (row) => (
              <div style={{ whiteSpace: 'normal' }}>
                {new Date(row.date).toLocaleDateString('en-GB')}
              </div>
            )
        },
        {
            name: 'Email', 
            selector: row => row.depemail,
            cell: (row) =>(
                <div style={{whiteSpace:'normal'}}>
                    {row.depemail}
                </div>
            )
        },
        {
            name: 'Amount', 
            selector: row => row.depamnt,
            cell: (row) =>{
                const amountNumber = parseFloat(row.depamnt); // Convert to number

                return (
                    <div style={{whiteSpace:'normal'}}>
                        ${amountNumber.toLocaleString()}
                    </div>
                );
            }
        },
        {
            name: 'Type', 
            selector: row => row.deptype,
            cell: (row) =>(
                <div style={{whiteSpace:'normal'}}>
                    {row.deptype}
                </div>
            )
        },
        {
          name: 'Status', 
          selector: row => row.depstatus,
          cell: (row) =>(
              <div style={{whiteSpace:'normal'}}>
                  {row.depstatus}
              </div>
          )
        },
        {
            name: 'Action', 
            cell: (row) =>(
                <div style={{whiteSpace:'normal', display:'flex', justifyItems: 'center', alignItems: 'center'}}>
                    <button onClick={() => deleteDeposit(row._id)} className="bg-red-700 text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2">Delete</button>
                </div>
            )
        },
    ];

  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-black' textColor = 'text-white' links = {AdminSideNav}  />

        {activedepForm && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updateactiveDepForm(false)}}  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit = {(e) => depositSubmit(e)}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='userId' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> UpdateDepForm({...depositForm, [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="amount" className='text-[15px] text-left text-[#000000d9] font-[450]'>Amount</label>
                            <input type="text" name='depositAmount' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> UpdateDepForm({...depositForm, [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Type</label>
                            <input type="text" name='depositType' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> UpdateDepForm({...depositForm, [e.target.name]:e.target.value})} />
                        </div>
                    </div>
                    

                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}

        {ProfField && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updateProfField(false)}}  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit = {(e) => profSubmit(e)}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='userId' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> UpdateProfForm({...ProfForm, [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="amount" className='text-[15px] text-left text-[#000000d9] font-[450]'>Amount</label>
                            <input type="text" name='profamount' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> UpdateProfForm({...ProfForm, [e.target.name]:e.target.value})}/>
                        </div>
                    </div>
                    

                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}

        {BonusField && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updateBonusField(false)}}  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit = {(e) => bonusSubmit(e)}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='userId' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> UpdateBonusForm({...BonusForm, [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="amount" className='text-[15px] text-left text-[#000000d9] font-[450]'>Amount</label>
                            <input type="text" name='BonusAmount' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> UpdateBonusForm({...BonusForm, [e.target.name]:e.target.value})}/>
                        </div>
                    </div>
                    

                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                </div>
            </div> 

            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>
                <p className='relative w-full text-center text-white'>Manage Deposit</p>

                <div className='relative min-h-[60px] w-full flex flex-col space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row justify-center items-center '>
                    <button onClick={()=> {updateactiveDepForm(true)}} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md'>Update Deposit</button>
                    <button onClick={()=> {updateProfField(true)}} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md'>Update Profit</button>
                    <button onClick={()=> {updateBonusField(true)}} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md'>Update Bonus</button>
                </div> 
                
                {/* all toast messages */}
                <div className='relative w-full '>
                    <ToastContainer          
                      className="relative w-full sm:w-[450px]"
                    />
                </div>

                {/* table start */}
                <div className='relative max-w-full flex justify-center items-center '>
                    <ReactDataTable columns = {columns} data = {allUsersDepData} />   
                </div>
               
            </div>
        </div>

    </div>
  )
}

export default AdminDeposits