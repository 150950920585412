import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Landing from './pages/Landing';
import Signup from './pages/Signup';
import Signin from './pages/Signin';
import Airdrops from './pages/Airdrops';
import About from './pages/About';
import Forgotpassword from './pages/Forgotpassword';
import Resetpassword from './pages/Resetpassword'
import RegistractionAuth from './pages/RegistractionAuth';


//user routes 
import UserRoutes from './pages/privateroutes/UserRoutes'

import UserDash from './pages/user/UserDash';
import UserDeposit from './pages/user/UserDeposit';
import UserKyc from './pages/user/UserKyc';
import UserTrade from './pages/user/Usertrade';
import UserTransactions from './pages/user/UserTransactions';
import Usertransfer from './pages/user/Usertransfer';
import UserProfile from './pages/user/UserProfile';
import UserWallets from './pages/user/UserWallets';

//admin routes 
import AdminRoutes from './pages/privateroutes/AdminRoutes'

import AdminDash from './pages/admin/AdminDash';
import AdminDeposits from './pages/admin/AdminDeposits';
import AdminWithdrawals from './pages/admin/AdminWithdrawals';
import AdminUsers from './pages/admin/AdminUsers';
import Admintrades from './pages/admin/Admintrades';


function App() {


  return (
    <Router>
      <Routes>

          <Route path="*" element={<Landing />}/>
          <Route path='/' element={<Landing />}/>
          <Route path='/signup' element={<Signup />}/>
          <Route path='/signin' element={<Signin />}/>
          <Route path='/airdrops' element={<Airdrops />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/forgotpassword' element={<Forgotpassword />}/>
          <Route path='/resetpassword' element={<Resetpassword />}/>
          <Route path='/completesignup' element={<RegistractionAuth />}/>

          <Route element={<UserRoutes />}>
            <Route path='/user/dashboard' element = {<UserDash />} />
            <Route path='/user/deposit' element = {<UserDeposit />} />
            <Route path='/user/trade' element = {< UserTrade/>} />
            <Route path='/user/transfer' element = {< Usertransfer />} />
            <Route path='/user/kyc' element = {<UserKyc />} />
            <Route path='/user/transactions' element = {<UserTransactions />} />
            <Route path='/user/updateprofile' element = {<UserProfile />} />
            <Route path='/user/wallets' element = {<UserWallets />} />
          </Route>

          <Route element={<AdminRoutes />}>
            <Route path='/admin/dashboard' element = {<AdminDash />} />
            <Route path='/admin/deposits' element = {<AdminDeposits />} />
            <Route path='/admin/trades' element = {<Admintrades />} />
            <Route path='/admin/withdrawals' element = {<AdminWithdrawals />} />
            <Route path='/admin/users' element = {<AdminUsers />} />
          </Route>


      </Routes>
    </Router>

  );
}

export default App;
