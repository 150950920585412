import axios from 'axios'
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link, useNavigate} from "react-router-dom";

import MobileMenu from './components/MobileMenu';

import spinner from '../images/Spinnerblack.svg'
import mainLogo from '../images/LogoWhite.svg'

const RegistractionAuth = () => {


    const [inactiveMobileNav, activeMobileNav] = useState(false);

  
    const navigate = useNavigate();
  
    //signup form submit
    const [signupConfValues, setsignupConfValues] = useState({
        userCode: "",
    });

    const [isLoadingBtn, updateisLoadingBtn] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  
    const [errorMessagesDefault, errorMessageUpdate] = useState([]);
  
    const signupConfSubmit = async (e) => {
      
      e.preventDefault();
      updateisLoadingBtn(true)
      setIsButtonDisabled(true)
  
      try {
        //send form data to backend
        const regResponse =  await axios.post('/api/signupconfirmation', {...signupConfValues,})
        const regResponseData = regResponse.data;
  
        //checks if data contains any errors
        if(regResponseData.errMsg){
            //update input field values to be displayed alongside errors
            setsignupConfValues({
                signupcode: ""
            })
            toast.error(regResponseData.errMsg, {
                position:"top-center",
                autoClose: 5000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
            window.scroll(0, 0)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }else{
            //success message from json data
            const succRegMsg = regResponseData.successMsg;  
            toast.success(succRegMsg, {
                position:"top-center",
                autoClose: 5000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
            setsignupConfValues({
                signupcode: ""
            })
  
            // Navigate to the Signin component
            setTimeout(()=>{
                navigate('/signin');  
            }, 5000)                 
        }                   
      } catch (error){
            console.log('error')
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
      }    
    } 
  
    //call function to display toast error messages 
    useEffect(() => {
  
      const displayErrorMessages = () => {
        errorMessagesDefault.forEach((errorMessage) => {
          if (errorMessage) {
              toast.error(errorMessage, {
                  position:"top-center",
                  autoClose: 5000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
              })
          }
        });
      };    
  
      displayErrorMessages();
  
    }, [errorMessagesDefault]);
  
  


  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full p-[1rem] min-h-screen ">
    
        {/* mobile menu */}
        <MobileMenu closeMobileMenu = {() => {activeMobileNav(false) }} translateAction = {inactiveMobileNav ? `translate-y-[0px]` : `translate-y-[-1500px]`} />          
        
        {/* nav section */}
        { <nav className="relative flex justify-between items-center min-h-[4rem] w-full md:w-[80%]">
            
          <Link to = '/' className="relative flex justify-start items-center h-full w-[120px]">
                <img src = {mainLogo} alt="" className='h-[60%]' />
          </Link>

        </nav>}                
        
        <div className='relative py-[7rem] min-h-[10rem] w-full flex flex-col justify-start items-start space-y-[2rem] md:w-[80%]'>
            
            <div className='relative w-full flex flex-col justify-start items-start'>
                <h1 className='text-white text-[22px] font-[550] pb-[0.50rem]'>Welcome To XolarChain</h1>
                <p className='text-[#8E8E93] pb-[1rem]'>Please enter the code sent to your email. </p>
                <ToastContainer className="relative w-full sm:w-[450px]"/>
            </div>

            <form action="" onSubmit={(e)=> signupConfSubmit(e)}>
                <div className='relative w-full flex flex-col space-y-4'>
                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="userCode" className='text-[15px] text-left text-white font-[450]'>Sign up code</label>
                        <input type="text" name='userCode' className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-white text-[16px] sm:w-[450px] focus:outline-none focus:border-primaryGreen' onChange = {(e) => setsignupConfValues({...signupConfValues, [e.target.name]:e.target.value})} />
                    </div>

                    <button  type='submit' disabled = {isButtonDisabled}  className='bg-primaryGreen text-white border-primaryGreen rounded-md flex justify-center items-center text-center w-full h-[50px] sm:w-[450px]'>
                        {isLoadingBtn ? <img src={spinner} className='w-[50px] h-[50px]' alt='loading'  /> : <p>Complete sign up</p>} 
                    </button>

                </div>
            </form>
        </div>

    </div>
  )
}

export default RegistractionAuth