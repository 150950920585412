import React, { useEffect } from 'react';
import { useState } from 'react';
import { useGlobalContext } from '../../context';
import SideNav from '../components/SideNav';
import {GiHamburgerMenu} from "react-icons/gi"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiLogOut} from "react-icons/bi" 
import {FiUsers} from "react-icons/fi"


const AdminDash = () => {

    const{allUsersMongoData, allUsersWithData} = useGlobalContext();
    const [pendingWithdrawals, setPendingWithdrawals] = useState([]);

    //side nav
    const [navActive, updateNavActive] = useState(null)

    const clearSess = () =>{
        window.sessionStorage.clear();
    }

    const hideNav = () =>{
        updateNavActive(false)
    }

    const AdminSideNav = [
        { url: '/admin/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/admin/deposits', name: 'Manage Deposit', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/admin/trades', name: 'Manage Trades', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/admin/withdrawals', name: 'Manage Withdrawals', icon: <BiHistory className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/admin/users', name: 'Manage Users', icon: <FiUsers className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];

    useEffect(()=>{
        const mapWithData = allUsersWithData.filter(
            (transaction) => transaction.withdrawstatus === 'Pending'
        );

        setPendingWithdrawals(mapWithData);
    
    }, [allUsersWithData])

  return (
    <div className='absolute overflow-x-hidden w-full min-h-full bg-white'>
        <SideNav hideNav={hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {AdminSideNav} />

        <div className='relative w-full h-full p-2 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-black' />
                    </button>
                    
                </div>
            </div>       
        </div>   

        {/* dashboard information display */}
        <div className='relative w-full min-h-[100px] flex flex-col justify-center items-center space-y-6 px-5 py-5'>
            {/* <!-- active users --> */}
            <div className="relative w-[100%] min-h-[100px] bg-black border border-white rounded-lg flex flex-col justify-center items-center px-5 py-5 md:w-[600px] xl:w-[900px]">
                <h3 className="font-[550] text-[20.50px] text-white text-center md:text-[23px]">Active Users</h3>
                <p className="font-[550] text-[19.50px] text-center text-[#dfe3e7] md:text-[20px]"> {allUsersMongoData.length}  </p>
            </div>

            {/* <!-- Pending withdrawals --> */}
            <div className="relative w-[100%] min-h-[100px] bg-black border border-white rounded-lg flex flex-col justify-center items-center px-5 py-5 md:w-[600px] xl:w-[900px]">
                <h3 className="font-[550] text-[20.50px] text-white text-center md:text-[23px]">Pending withdrawals</h3>
                <p className="font-[550] text-[19.50px] text-center text-[#dfe3e7] md:text-[20px]"> {pendingWithdrawals.length} </p>
            </div>
        </div>

    </div>
  )
}

export default AdminDash